import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAttendee, CreateAttendeeParams } from '../api';

export const useCreateAttendee = () => {
  const queryClient = useQueryClient();

  return useMutation((params: CreateAttendeeParams) => createAttendee(params), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['attendees'] });
    },
  });
};
