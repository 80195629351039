import { getValuesForAttribute, searchContacts } from '@api';
import { FieldStates } from '../../constants';

const PER_PAGE = 10;

export const loadOptions =
  (id: string, attribute: string | [string, any]) =>
  async (query: string, _prev: any, opts?: { page: number }) => {
    const page = opts?.page || 1;

    try {
      const options = await getValuesForAttribute(id, {
        attribute,
        query,
        page,
        per_page: PER_PAGE,
      });

      return {
        options,
        hasMore: options.length >= PER_PAGE,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return { options: [], hasMore: false };
    }
  };

export const loadAttendeesOptions =
  (internal: boolean, active_at: Date) =>
  async (query: string, _prev: any, opts?: { page: number }) => {
    const page = opts?.page || 1;

    try {
      const response = await searchContacts({
        contact_search: {
          multifield_search: query,
          internal,
          active_at,
        },
        page,
        per_page: PER_PAGE,
      });

      const options = response.data.results.map((contact: Contact) => ({
        value: `${contact.name}:${contact.email || ''}`,
        label: `${contact.name} ${contact.email ? `(${contact.email})` : ''}`,
      }));

      return {
        options,
        hasMore: options.length >= PER_PAGE,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return { options: [], hasMore: false };
    }
  };

export const fieldProps = (state: FieldStates) => ({
  required: state === FieldStates.required,
  disabled: state === FieldStates.disabled,
});
