import React from 'react';
import { PageFlip, Xmark } from 'iconoir-react';
import tw, { styled } from 'twin.macro';
import { t } from 'i18next';

import BadgeIcon from '../BadgeIcon';

const FieldWrapper = styled.div`
  ${tw`relative w-full h-full flex flex-col items-center justify-center p-6`}
  ${tw`border border-blueGray-100 rounded-lg`}
  ${tw`text-xs text-center bg-blueGray-020`}
`;

const IconWrapper = styled.div`
  ${tw`
    relative flex justify-center
    text-blue-500
    pb-4
  `}
`;

const FileLostPreview = () => (
  <FieldWrapper>
    <IconWrapper>
      <PageFlip tw="w-[74px] h-[74px]" />
      <BadgeIcon size="md" color="red" tw="absolute -top-[8px] -right-[8px] bg-white">
        <Xmark />
      </BadgeIcon>
    </IconWrapper>
    <span tw="text-blue-700 text-xs font-semibold pb-6">{t('file_lost_preview.lost_receipt')}</span>
  </FieldWrapper>
);

export default FileLostPreview;
