import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Hit } from 'instantsearch.js';

type Index = {
  ids: string[];
  all: boolean;
  amount: number;
};

type Type = {
  indexId: string;

  selectedIds: string[];
  allSelected: boolean;
  selectedAmount: number;

  isSelected: (hit: Hit) => boolean;
  select: (hit: Hit) => void;
  unselect: (hit: Hit) => void;
  toggleSelect: (hit: Hit) => void;
  selectMany: (hits: Hit[]) => void;
  selectAll: (value?: boolean) => void;
};

const SelectedHitsContext = createContext({} as Type);

export const useSelectedHits = () => useContext(SelectedHitsContext);

export const SelectedHitsProvider = ({
  indexId,
  children,
}: React.PropsWithChildren<{ indexId: string }>) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [indexes, setIndexes] = useState<{ [index: string]: Index }>({});

  const is = useCallback(
    (hit: Hit) => (isDesktop && indexes[indexId]?.ids.includes(hit.objectID)) || false,
    [indexId, indexes, isDesktop],
  );

  const set = useCallback(
    (hit: Hit) => {
      setIndexes({
        ...indexes,
        [indexId]: {
          ids: [...(indexes[indexId]?.ids || []), hit.objectID],
          all: false,
          amount: (indexes[indexId]?.amount || 0) + Math.abs(hit.amount_real),
        },
      });
    },
    [indexId, indexes],
  );

  const unset = useCallback(
    (hit: Hit) => {
      const amount = (indexes[indexId]?.amount || 0) - Math.abs(hit.amount_real);

      setIndexes({
        ...indexes,
        [indexId]: {
          ids: indexes[indexId]?.ids.filter(i => i !== hit.objectID) || [],
          all: false,
          amount: amount > 0 ? amount : 0,
        },
      });
    },
    [indexId, indexes],
  );

  const toggle = useCallback((hit: Hit) => (is(hit) ? unset(hit) : set(hit)), [is, set, unset]);

  const setMany = useCallback(
    (hits: Hit[]) => {
      const ids = hits.map(hit => hit.objectID);
      const amount = hits.reduce((acc, hit) => acc + Math.abs(hit.amount_real), 0);

      setIndexes({
        ...indexes,
        [indexId]: {
          ids,
          all: false,
          amount,
        },
      });
    },
    [indexId, indexes],
  );

  const setAll = useCallback(
    (value = true) => {
      setIndexes({
        ...indexes,
        [indexId]: {
          ...(indexes[indexId] || {}),
          all: value,
          amount: 0,
        },
      });
    },
    [indexId, indexes],
  );

  const value = useMemo(
    () => ({
      indexId,
      allSelected: (isDesktop && indexes[indexId]?.all) || false,
      selectedIds: isDesktop ? indexes[indexId]?.ids || [] : [],
      selectedAmount: indexes[indexId]?.amount || 0,
      isSelected: is,
      select: set,
      unselect: unset,
      toggleSelect: toggle,
      selectMany: setMany,
      selectAll: setAll,
    }),
    [indexId, indexes, is, set, setAll, setMany, toggle, unset],
  );

  return <SelectedHitsContext.Provider value={value}>{children}</SelectedHitsContext.Provider>;
};
