import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { NavArrowLeft, NavArrowRight } from 'iconoir-react';

export type Props = React.PropsWithChildren<{}>;

export type TabProps = React.ComponentProps<'div'> & {
  onClick: () => void;
  isActive: boolean;
};

const SCROLLWIDTH = 200;

const StyledTabWrapper = styled.div<{ $isActive: boolean }>`
  ${tw`flex items-end border-b`}
  ${({ $isActive }) => $isActive && tw`border-b-white`};
`;

const StyledContent = styled.button<{ $isActive: boolean }>`
  ${tw`
    whitespace-nowrap
    bg-transparent
    pt-2.25 pb-1.5 px-4
    border-x border-t border-b-4 rounded-t-lg border-transparent
    font-semibold text-sm leading-5 text-blueGray-800
  `}
  ${StyledTabWrapper}:hover & {
    ${tw`border-blueGray-100 border-b-transparent text-blue-700`};
    ${({ $isActive }) => $isActive && tw`text-blue-200`}
  }
  ${({ $isActive }) => $isActive && tw`border-b-transparent border-blueGray-100 text-blue-200`}
`;

const StyledSpacer = styled.div<{ $isActive: boolean; $position: 'left' | 'right' }>`
  ${tw`
    bg-transparent
    -mb-px
    w-1.5 h-1/4
    border-b border-transparent
  `}
  ${({ $position }) =>
    $position === 'right' ? tw`border-r rounded-br-lg` : tw`border-l rounded-bl-lg`}
  ${({ $isActive }) => $isActive && tw`border-blueGray-100`}
  ${StyledTabWrapper}:hover & {
    ${tw`border-blueGray-100`}
  }
`;

const StyledButton = styled.button`
  ${tw`flex items-center p-2`}
  ${tw`bg-transparent text-primary disabled:text-blueGray-300`}
  ${tw`[> svg]:(w-4 h-4 stroke-2)`}
`;

const SrollWrapper = styled.div`
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scrollbar-width: none;
  ${tw`overflow-x-scroll -mb-px pl-1 md:pl-6`}
`;

const Tab = ({ onClick, children, isActive = false }: TabProps) => (
  <StyledTabWrapper $isActive={isActive}>
    <StyledSpacer $isActive={isActive} $position="right" />
    <div css="margin-right:-1px; margin-left:-1px;">
      <StyledContent onClick={onClick} type="button" $isActive={isActive}>
        {children}
      </StyledContent>
    </div>
    <StyledSpacer $isActive={isActive} $position="left" />
  </StyledTabWrapper>
);

const Tabs = ({ children }: Props) => {
  const scrollRef = useRef<HTMLInputElement>(null);
  const [disabled, setIsDisabled] = useState<'prev' | 'next' | null>();
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  const onScroll = (target: any) => {
    if (target.scrollLeft === target.scrollLeftMax) {
      setIsDisabled('next');
    } else if (target.scrollLeft === 0) {
      setIsDisabled('prev');
    } else {
      setIsDisabled(null);
    }
  };

  useEffect(() => {
    if (scrollRef.current?.scrollWidth) {
      setIsScrollable(scrollRef.current?.scrollWidth > scrollRef.current?.clientWidth);
      onScroll(scrollRef.current);
    }
  }, [scrollRef]);

  const handleScroll = (prev = false) => {
    // TODO: Improvement scroll to the next/prev tab
    if (scrollRef.current) {
      if (prev) {
        scrollRef.current.scrollLeft -= SCROLLWIDTH;
      } else {
        scrollRef.current.scrollLeft += SCROLLWIDTH;
      }
    }
  };

  return (
    <div tw="flex border-b justify-between">
      <SrollWrapper ref={scrollRef} onScroll={e => onScroll(e.target)}>
        <div tw="flex">{children}</div>
      </SrollWrapper>
      {isScrollable && (
        <div tw="flex" css="box-shadow: -10px 0px 10px -5px rgba(0, 0, 0, 0.09);">
          <StyledButton
            onClick={() => handleScroll(true)}
            disabled={disabled === 'prev'}
            aria-label="Previous">
            <NavArrowLeft />
          </StyledButton>
          <StyledButton
            onClick={() => handleScroll()}
            disabled={disabled === 'next'}
            aria-label="Next">
            <NavArrowRight />
          </StyledButton>
        </div>
      )}
    </div>
  );
};

Tabs.Tab = Tab;

export default Tabs;
