import React from 'react';
import { useHits, HitsProps, useInstantSearch } from 'react-instantsearch';
import 'twin.macro';

import { useDebounce } from 'use-debounce';

export type Props = HitsProps<ExpenseHit> & {
  skeleton?: React.JSXElementConstructor<{}>;
  head: React.ReactNode;
  emptyMessage: React.ReactNode;
  errorMessage: React.ReactNode;
};

const Hits = ({ hitComponent, skeleton, head, emptyMessage, errorMessage, ...props }: Props) => {
  const { status } = useInstantSearch();
  const { items, sendEvent } = useHits(props);
  const [dbStatus] = useDebounce(status, 250);

  if (!hitComponent) {
    throw new Error('hitComponent is undefined');
  }

  const HitComponent = hitComponent;
  const Skeleton = skeleton;

  const isLoading = dbStatus === 'loading' || dbStatus === 'stalled';
  const isEmpty = dbStatus === 'idle' && !items.length;
  const hasError = dbStatus === 'error';

  return (
    <>
      {head}
      <div>
        {items.map(hit => (
          <HitComponent key={hit.objectID} hit={hit} sendEvent={sendEvent} />
        ))}
        {isLoading && Skeleton && [1, 2, 3].map(i => <Skeleton key={`sk-${i}`} />)}
      </div>
      {isEmpty && emptyMessage}
      {hasError && errorMessage}
    </>
  );
};

export default Hits;
