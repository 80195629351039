import { snakeize } from '@helpers/object';
import { FieldStates } from '@screens/Expenses/constants';

import { apiClient } from '../constants';
import { formatGetExpense, formatVatRate, formatPostExpenseParams } from './expenses/helpers';

export type FieldsToDisplayResponse = {
  fields: Record<string, FieldStates>;
  vat_deductible_percent: number;
};

export type PostCorrectionRequestParams = {
  message: string;
  notification: boolean;
};
export type PostReversedExpenseParams = {
  reason: string;
  otherReason: string;
  amount: number;
  message: string;
};

export type GetVatsParams = {
  companyCountry: string;
  invoiceCountry: string;
  vatTax: string;
};

export const postCurrentAlgoliaSearch = (uiState: any) =>
  apiClient.post('/current_algolia_search', { filters: snakeize(uiState) });

export const postSiphonParamsToAlgoliaAttributes = (searchParams: URLSearchParams) =>
  apiClient.post('/siphon_to_algolia', searchParams).then(({ data }) => data);

export const postExpensesBatchUpates = (params: any) =>
  apiClient.post('/expenses/batch_updates', snakeize(params)).then(({ data }) => data);

export const postExpensesBatchStepUp = (params: any) =>
  apiClient.post('/expenses/update_all', params).then(({ data }) => data);

export const getExpense = (id: string) =>
  apiClient.get(`/expenses/${id}/values`).then(({ data }) => formatGetExpense(data));

export const updateExpense = async (id: string, params: ExpenseFormValues) =>
  apiClient
    .patch(`/expenses/${id}`, formatPostExpenseParams(params))
    .then(({ data }) => formatGetExpense(data));

export const getFieldsToDisplay = (id: string, step: string, expense_category_id?: string) =>
  apiClient
    .get<FieldsToDisplayResponse>(`/expenses/${id}/fields`, {
      params: { step, expense_category_id },
    })
    .then(({ data }) => ({
      fields: data.fields,
      vatDeductiblePercent: data.vat_deductible_percent,
    }));

export const getValuesForAttribute = (
  id: string,
  {
    attribute,
    query,
    page,
    per_page,
  }: { attribute: string | [string, any]; query?: string; page?: number; per_page?: number },
) => {
  const params = Array.isArray(attribute)
    ? { attribute_name: attribute[0], attribute_value: attribute[1] }
    : { attribute_name: attribute };

  return apiClient
    .get<{ label: string; value: string }[]>(`/expenses/${id}/values_for_attribute`, {
      params: { ...params, query, page, per_page },
    })
    .then(({ data }) => data);
};

export const postCorrectionRequest = (id: string, params: PostCorrectionRequestParams) =>
  apiClient.post('/correction_requests', {
    correction_request: {
      expense_id: id,
    },
    comment: {
      expense_id: id,
      message: params.message,
      send_email: params.notification,
    },
  });

export const cancelCorrectionRequest = (id: string) =>
  apiClient.delete(`/correction_requests/not-null-id`, {
    data: {
      correction_request: {
        expense_id: id,
      },
    },
  });

export const postReversedExpense = async (id: string, params: PostReversedExpenseParams) =>
  apiClient.post('/reversed_expenses', {
    reversed_expense: {
      original_expense_id: id,
      reason: params.reason,
      other_reasons: params.otherReason,
      amount: params.amount,
      send_email: '1',
    },
    comment: {
      message: params.message || '',
    },
  });

export const cancelReversedExpense = (reversedExpenseId: string) =>
  apiClient.delete(`/reversed_expenses/${reversedExpenseId}`);

export const getVats = (id: string | null, params: GetVatsParams) => {
  const base = id ? `/expenses/${id}/vats` : '/vats_blank';

  const usecase = params.vatTax === 'excluded' ? 'reverse_charge' : '';
  const country = params.vatTax === 'excluded' ? params.companyCountry : params.invoiceCountry;

  return apiClient
    .get(base, {
      params: {
        vat_usecase: usecase,
        country,
      },
    })
    .then(({ data }) => data.map(formatVatRate) as VAT[]);
};
