import React from 'react';
import loadable from '@loadable/component';

import { paths } from './constants';

const Suppliers = loadable(() => import('../screens/suppliers/list'));

const routes = [
  {
    path: paths.SUPPLIERS,
    element: <Suppliers />,
  },
  {
    path: paths.SUPPLIER,
    element: <Suppliers routeState="show" />,
  },
  {
    path: paths.NEW_SUPPLIER,
    element: <Suppliers routeState="new" />,
  },
  {
    path: paths.EDIT_SUPPLIER,
    element: <Suppliers routeState="edit" />,
  },
];

export default routes;
