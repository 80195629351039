import React, { createContext, useContext, useState, useMemo } from 'react';

type Type = {
  allOpened: boolean;
  openAll: (value?: boolean) => void;
};

export const OpenedHitsContext = createContext({} as Type);

export const useOpenedHits = () => useContext(OpenedHitsContext);

export const OpenedHitsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [allOpened, setAllOpened] = useState(false);

  const openAll = (value = true) => setAllOpened(value);

  const value = useMemo(() => ({ allOpened, openAll }), [allOpened]);

  return <OpenedHitsContext.Provider value={value}>{children}</OpenedHitsContext.Provider>;
};
