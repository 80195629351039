import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle, InfoCircle } from 'iconoir-react';
import tw from 'twin.macro';

import { DEFAULT_CURRENCY } from '@constants';
import {
  Button,
  Drawer,
  InputText,
  Message,
  RadioButton,
  TextArea,
  Form,
  ContextField as Field,
  formatError,
} from '@new-components';

import { toastify } from '@helpers/toastify';
import { usePostReport } from '@hooks/useExpenses';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  expenseId: string;
  data: Expense;
};

type FormValues = {
  reason: string;
  otherReason: string;
  amount: number;
  message: string;
};

const reasonOptions = [
  { value: 'out_of_politic', label: 'reversed_expenses.form.reason_labels.out_of_politic' },
  { value: 'out_of_work_time', label: 'reversed_expenses.form.reason_labels.out_of_work_time' },
  {
    value: 'amount_out_of_politic',
    label: 'reversed_expenses.form.reason_labels.amount_out_of_politic',
  },
  { value: 'exceeding_limits', label: 'reversed_expenses.form.reason_labels.exceeding_limits' },
  { value: 'other', label: 'reversed_expenses.form.reason_labels.other' },
];

const Card = tw.div`flex flex-col border border-blueGray-100 rounded-lg p-8 my-4 items-center justify-center`;
const Bloc = tw.div`bg-blueGray-020 rounded-lg p-6 my-4`;

const ReportDrawer = ({ isOpen, onClose, expenseId, data }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: postReportExpense, isLoading } = usePostReport(expenseId);

  const maxAmount = Math.abs(data.amount || 0);

  const defaultValues: Partial<FormValues> = useMemo(
    () => ({
      amount: maxAmount,
      reason: reasonOptions[0].value,
    }),
    [maxAmount],
  );

  const validateAmount = (value: string) => {
    const amount = parseFloat(value);

    if (amount > maxAmount || amount <= 0)
      return t('expenses.report_drawer.amount_exceeds_limit', {
        value: maxAmount,
        currency: DEFAULT_CURRENCY,
      });

    return true;
  };

  const onSubmit = async (values: FormValues) => {
    if (!expenseId) return;

    try {
      await postReportExpense(values);
      toastify('success', t('reversed_expense.create.success'));
      onClose();
    } catch (err) {
      toastify('error', t('globals.error_occurred'));
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Drawer.RightPanel>
        <Form onSubmit={onSubmit} defaultValues={defaultValues} tw="h-full overflow-scroll">
          <h3>{t('expenses.report_drawer.report')}</h3>
          {data && (
            <Card>
              <span tw="text-sm block text-blueGray-800 pb-4">
                {t('{{date, human}}', { date: new Date(data.at) })}
              </span>
              <span tw="text-4xl block text-blue-500 pb-3 font-semibold">
                {t('{{value, currency}}', {
                  value: Math.abs(data.amount),
                  currency: DEFAULT_CURRENCY,
                })}
              </span>
              <span tw="text-sm block text-blue-700">{data.merchantName}</span>
            </Card>
          )}
          <Message size="md" icon={<InfoCircle />} variant="info">
            <div>{t('expenses.report_drawer.message')}</div>
          </Message>
          <Bloc>
            <h5 tw="mb-4">{t('expenses.report_drawer.out_of_policy.title')}</h5>
            <label htmlFor="amount" tw="text-label">
              {t('expenses.report_drawer.out_of_policy.amount')}
            </label>
            <Field
              name="amount"
              label={t('expenses.report_drawer.out_of_policy.amount')}
              required
              validate={validateAmount}>
              <InputText
                id="amount"
                type="number"
                placeholder={t('expenses.correction_request.drawer.enter_here')}
              />
            </Field>
          </Bloc>
          <Bloc>
            <h5 tw="mb-4">{t('reversed_expenses.form.reason_labels.report_reason')}</h5>
            <Field
              name="reason"
              label={t('reversed_expenses.form.reason_labels.report_reason')}
              required
              render={({ field, fieldState }) => (
                <div tw="flex flex-col gap-y-2">
                  {reasonOptions.map(option => (
                    <label key={option.value} tw="text-sm block">
                      <RadioButton
                        value={option.value}
                        checked={field.value === option.value}
                        onChange={field.onChange}
                      />
                      <span tw="ml-2 align-bottom">{t(option.label)}</span>
                    </label>
                  ))}
                  {field.value === 'other' && (
                    <Field
                      name="otherReason"
                      label={t('reversed_expenses.form.reason_labels.report_reason')}
                      required>
                      <TextArea
                        placeholder={t('reversed_expenses.form.reason_labels.specify_reason')}
                        tw="resize-none"
                      />
                    </Field>
                  )}
                  <div>
                    {fieldState.error && (
                      <span tw="text-xs text-red-500">
                        {formatError(t, fieldState.error, { name: 'reason', label: 'Reason' })}
                      </span>
                    )}
                  </div>
                </div>
              )}
            />
          </Bloc>
          <Bloc>
            <h5 tw="mb-4">{t('expenses.report_drawer.add_comment')}</h5>
            <Field name="message" label="Comment">
              <TextArea
                placeholder={t('expenses.correction_request.drawer.enter_here')}
                tw="resize-none"
              />
            </Field>
          </Bloc>
          <Drawer.Footer>
            <div tw="flex gap-4 justify-between">
              <Button size="md" shade="secondary" tw="flex-1" onClick={onClose}>
                {t('globals.cancel')}
              </Button>
              <Button
                type="submit"
                size="md"
                shade="primary"
                tw="flex-1"
                leftIcon={<CheckCircle />}
                isLoading={isLoading}>
                {t('expenses.list.table.report')}
              </Button>
            </div>
          </Drawer.Footer>
        </Form>
      </Drawer.RightPanel>
    </Drawer>
  );
};

export default ReportDrawer;
