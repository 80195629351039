import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

type ContextType = {
  state: any;
  openModal: (name: string, props: any) => void;
  closeModal: () => void;
};

const initialState = {
  name: null,
  props: {},
};

const ModalContext = createContext<ContextType>({
  state: initialState,
} as ContextType);

export const useModal = () => useContext(ModalContext);

const reducer = (state: any, { type, name, props }: any) => {
  switch (type) {
    case 'OPEN_MODAL':
      return {
        name,
        props,
      };
    case 'CLOSE_MODAL':
      return {
        name: null,
        props: {},
      };
    default:
      return state;
  }
};

export const ModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = useCallback(
    (name: string, props: any) => dispatch({ type: 'OPEN_MODAL', name, props }),
    [dispatch],
  );
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch]);

  const value = useMemo(() => ({ state, openModal, closeModal }), [state, openModal, closeModal]);

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
