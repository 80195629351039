import React from 'react';
import tw from 'twin.macro';

const StyledActionBar = tw.div`
  w-full py-2 px-4
  rounded-lg
  flex items-center justify-between
  bg-blue-700 text-white text-sm
`;

const ActionBar = ({ children, ...props }: React.ComponentProps<'div'>) => (
  <div tw="px-3" {...props}>
    <StyledActionBar>{children}</StyledActionBar>
  </div>
);

export default ActionBar;
