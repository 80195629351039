import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useStats } from 'react-instantsearch';
import { CheckCircle } from 'iconoir-react';
import 'twin.macro';

import { postExpensesBatchStepUp } from '@api';
import { toastify } from '@helpers/toastify';
import { Button, ActionBar } from '@new-components';
import { ActionTabs, nextSteps } from '../constants';
import { useSelectedHits } from '../contexts/SelectedHits';
import ConfirmModal from '../components/ConfirmBulkStepUpModal';

const BulkActionBar = () => {
  const { t } = useTranslation();
  const { indexId, allSelected, selectedIds, selectedAmount, selectMany } = useSelectedHits();
  const { nbHits } = useStats();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const nb = allSelected ? nbHits : selectedIds.length;

  const nextStep = nextSteps[indexId as ActionTabs];

  if (nb === 0) return null;

  const onConfirmSubmit = async () => {
    const params = {
      ids: allSelected ? [] : selectedIds,
      all: allSelected,
      attributes: {
        step: nextStep,
      },
    };

    try {
      postExpensesBatchStepUp(params).then(response => {
        if (response.success) toastify('success', response.success);
        if (response.error) toastify('error', response.error);
        selectMany([]);
      });
    } catch (err: any) {
      if (err.request) {
        const response = JSON.parse(err.request.responseText);
        if (response.error) toastify('error', response.error);
      } else {
        toastify('error', t('globals.error_occurred'));
      }
    }

    setIsConfirmModalOpen(false);
  };

  const onSubmit = () => setIsConfirmModalOpen(true);

  return (
    <>
      <ActionBar tw="sticky bottom-4">
        <div tw="font-semibold">
          <span>
            {t(
              nb > 1000
                ? 'expenses.bulk.thousand_selected_expenses'
                : 'expenses.bulk.nb_selected_expenses',
              { count: nb },
            )}
          </span>
          {!allSelected && (
            <span tw="ml-4 pl-4 border-solid border-l border-l-white">
              {t('globals.total')} :{' '}
              {t('{{amount, currency}}', { amount: selectedAmount, currency: 'EUR' })}
            </span>
          )}
        </div>
        <div tw="flex flex-row gap-2">
          <Button shade="white" leftIcon={<CheckCircle tw="text-blue-100" />} onClick={onSubmit}>
            <span>
              <Trans
                i18nKey="expenses.bulk.step_up_to"
                components={{ 1: <span tw="font-normal" /> }}
                values={{ step: t(`expenses.attributes.step.${nextStep}`) }}
              />
            </span>
          </Button>
        </div>
      </ActionBar>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onAccept={onConfirmSubmit}
        nbHits={nb}
        step={nextStep}
      />
    </>
  );
};

export default BulkActionBar;
