// Related to: https://github.com/import-js/eslint-plugin-import/issues/1479
import { de, enGB, es, fr, it, nl } from 'date-fns/locale';
import { format as formatDate, formatDistanceToNow } from 'date-fns';

import { Settings } from 'luxon';
import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from 'react-datepicker';

registerLocale('de', de);
registerLocale('en', enGB);
registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('it', it);
registerLocale('nl', nl);

const locales: { [key: string]: object } = {
  de,
  en: enGB,
  es,
  fr,
  it,
  nl,
};

const formats: { [key: string]: string } = {
  short: 'P',
  short_no_year: 'dd/MM',
  human: 'PP',
  human_short: 'd MMM',
  datetime: 'PPpp',
  long: 'PPPP',
  month: 'MMMM',
  month_year: 'MMM yyyy',
};

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (locale: string, namespace: string) => import(`./locales/${locale}/${namespace}.json`),
    ),
  )
  .init({
    ns: ['translation', 'country'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    lng: document.documentElement.lang,
    interpolation: {
      escapeValue: false,
      format: (value, format: any, lng: any, options) => {
        if (format === 'currency' && options?.currency)
          return new Intl.NumberFormat(lng, {
            ...options,
            style: 'currency',
            // currency: options.currency,
          }).format(value);

        const date = new Date(value);

        if (!Number.isNaN(date.getTime())) {
          const locale = locales[lng];

          if (format === 'distance') return formatDistanceToNow(date, { locale });
          return formatDate(date, formats[format] || format, { locale });
        }

        return value;
      },
    },
  });

Settings.defaultLocale = document.documentElement.lang;

export default i18n;
