import React from 'react';
import loadable from '@loadable/component';
import { paths } from './constants';

const Companies = loadable(() => import('../screens/Companies'));

const routes = [
  {
    path: paths.COMPANIES,
    element: <Companies />,
  },
];

export default routes;
