import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';

import { getCountryByAlpha3 } from '@helpers/countries';
import { RefinementList } from '@new-components/InstantSearch';
import { SideBar, Badge } from '@new-components';

const transformItems = (items: any[]) =>
  items.map(item => ({
    ...item,
    label: getCountryByAlpha3(item.label)?.label || item.label,
  }));

const SupplierCountry = () => {
  const { t } = useTranslation();
  const { indexUiState, indexRenderState } = useInstantSearch();
  const length = indexUiState.refinementList?.supplier_country?.length || 0;
  const canRefine = indexRenderState.refinementList?.supplier_country?.canRefine;

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title>
        {t('expenses.list.filters.supplier_country')}
        {length > 0 && (
          <Badge color="yellow" shape="rounded">
            {length}
          </Badge>
        )}
      </SideBar.Item.Title>
      <RefinementList
        attribute="supplier_country"
        sortBy={['count']}
        transformItems={transformItems}
      />
    </SideBar.Item>
  );
};

export default SupplierCountry;
