import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { ClockRotateRight, Prohibition, Undo, TriangleFlagTwoStripes } from 'iconoir-react';

import { DEFAULT_CURRENCY } from '@constants';
import { BadgeIcon, Tooltip, Td } from '@new-components';

export type Props = {
  hit: ExpenseHit;
  isIncoming: boolean;
};

const TdAmount = ({ hit, isIncoming }: Props) => {
  const { t } = useTranslation();

  const isPositive = hit.amount_real > 0;
  const isOutOfPolicy =
    hit.out_of_company_policy === 'out_of_policy' ||
    hit.out_of_company_policy === 'for_personal_expense';
  const isReversed = hit.source_type === 'ReversedExpense';
  const isForeign = hit.currency && hit.currency !== DEFAULT_CURRENCY;
  const isInCorrection = hit.active_correction_request;

  const isMultiLine = isOutOfPolicy || isReversed || isForeign || isInCorrection;

  return (
    <Td
      tw="w-[1%]"
      css={isMultiLine && tw`py-1.5`}
      aria-label={hit.amount.toString()}
      href={`${hit.edit_link}?restore_page=1`}>
      <div>
        {isIncoming && (
          <Tooltip.Wrapper>
            <Tooltip position="left" arrow="end">
              {t(
                hit.is_fuel_dispenser
                  ? 'expenses.list.table.fuel_incoming'
                  : 'expenses.list.table.incoming',
              )}
            </Tooltip>
            <BadgeIcon color="gray" size="sm" tw="mr-1">
              <ClockRotateRight />
            </BadgeIcon>
          </Tooltip.Wrapper>
        )}
        {!isIncoming && isOutOfPolicy && (
          <Tooltip.Wrapper>
            <Tooltip position="left" arrow="end">
              {t(
                hit.out_of_company_policy === 'out_of_policy'
                  ? 'expenses.list.table.out_of_policy'
                  : 'expenses.list.table.out_of_policy_for_personal',
              )}
            </Tooltip>
            <BadgeIcon color="orange" size="sm" tw="mr-1">
              <Prohibition />
            </BadgeIcon>
          </Tooltip.Wrapper>
        )}
        {!isIncoming && !isOutOfPolicy && isInCorrection && (
          <BadgeIcon color="orange" size="sm" tw="mr-1">
            <TriangleFlagTwoStripes />
          </BadgeIcon>
        )}
        {isReversed && (
          <BadgeIcon color="orange" size="sm" tw="mr-1">
            <Undo />
          </BadgeIcon>
        )}
        <span
          tw="font-heading font-semibold text-sm align-middle"
          css={[isPositive && !isReversed && tw`text-positive`]}>
          {isPositive && '+'}
          {t('{{value, currency}}', {
            value: Math.abs(hit.amount_real),
            currency: DEFAULT_CURRENCY,
          })}
        </span>
      </div>
      <div>
        {isOutOfPolicy && (
          <span tw="text-xs font-bold text-blueGray-700">
            {t('expenses.list.table.out_of_company_policy_amount', {
              amount: Math.abs(hit.overage_expense_amount),
              currency: DEFAULT_CURRENCY,
            })}
          </span>
        )}
        {isReversed && (
          <span tw="text-xs font-bold text-blueGray-700">
            {t('expenses.list.table.initial_expense_amount', {
              amount: Math.abs(hit.original_expense_amount),
              currency: DEFAULT_CURRENCY,
            })}
          </span>
        )}
        {isForeign && (
          <span tw="block text-xs font-bold text-blueGray-700">
            {t('{{value, currency}}', {
              value: Math.abs(hit.amount_currency),
              currency: hit.currency,
            })}
          </span>
        )}
        {!isIncoming && !isOutOfPolicy && isInCorrection && (
          <span tw="block text-xs font-bold text-blueGray-700">
            {t('expenses.list.table.active_correction_request')}
          </span>
        )}
      </div>
    </Td>
  );
};

export default TdAmount;
