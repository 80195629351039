import React from 'react';
import tw, { styled } from 'twin.macro';
import { Xmark, Sparks } from 'iconoir-react';

export type Props = Omit<React.ComponentProps<'div'>, 'onChange'> & {
  size?: 'sm' | 'md' | 'lg';
  shade?: 'default' | 'invert';
  icon?: React.ReactElement | null;
  hasError?: boolean;
  error?: string;
  isFocused?: boolean;
  isDisabled?: boolean;
  rightEl?: React.ReactElement;
  children: React.ReactElement;
  isOCR?: boolean;
};

type StyledProps = {
  size?: 'sm' | 'md' | 'lg';
  $shade?: 'default' | 'invert';
  $hasLeftIcon?: boolean;
  $hasRightEl?: boolean;
  $hasError?: boolean;
  $isFocused?: boolean;
  $isDisabled?: boolean;
  $isOCR?: boolean;
};

const sizings = ({ $hasLeftIcon, $hasRightEl }: Partial<StyledProps>) => ({
  sm: [tw`py-1.5 px-3 text-xs leading-5`, $hasLeftIcon && tw`pl-2`, $hasRightEl && tw`pr-2`],
  md: [$hasLeftIcon && tw`pl-3`, $hasRightEl && tw`pr-3`],
  lg: [tw`py-4.5`, $hasLeftIcon && tw`pl-3.5`, $hasRightEl && tw`pr-3.5`],
});

const disabledStyles = `border-blueGray-100 text-blueGray-700 bg-blueGray-050 cursor-not-allowed hover:bg-blueGray-050`;

export const StyledWrapper = styled.div<StyledProps>`
  ${tw`flex items-center gap-2`}
  ${tw`w-full px-4 py-2.5`}
  ${tw`text-sm font-normal border rounded-lg`}
  ${tw`bg-white hover:bg-white`}


  ${({ $shade }) => $shade === 'invert' && tw`bg-transparent`}
  ${({ size, $hasLeftIcon, $hasRightEl }) => size && sizings({ $hasLeftIcon, $hasRightEl })[size]}
  ${({ $hasError }) => $hasError && tw`border-red-500`}
  ${({ $isFocused }) =>
    $isFocused && tw`outline-none ring-0 bg-white border-blue-100 shadow-blue-100 shadow-sm-over`}
  ${tw`group-disabled:(${disabledStyles})`}
  ${({ $isDisabled }) => $isDisabled && tw`${disabledStyles}`}
  ${({ $isFocused, $isDisabled, $hasError }) =>
    !$isFocused && !$isDisabled && !$hasError && tw`hover:(border-blue-050)`}
  ${({ $isOCR }) => $isOCR && tw`text-purple-700`}

  input, textarea {
    ${tw`w-full border-0 bg-transparent focus:(outline-none ring-0)`}
    ${tw`placeholder:text-blueGray-700`}
    ${tw`disabled:(text-blueGray-700 bg-blueGray-050 cursor-not-allowed)`}
  }

  /*
    TO KNOW: will not work on Firefox < 121
    see: https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
  */
  &:has(> input:not(:placeholder-shown)):not(:hover) {
    ${({ $isFocused, $shade }) => $shade === 'invert' && !$isFocused && tw`bg-blue-005`}
  }
`;

const IconWrapper = styled.span<{ $hasError: boolean }>`
  ${tw`flex flex-[0 0 1.25rem] items-center justify-center`}
  ${tw`w-5 h-5 p-0.5`}
  ${tw`text-primary-2 `}

  > svg {
    ${tw`w-3.5 h-3.5 stroke-2`}
  }

  ${({ $hasError }) => $hasError && tw`text-error`}
`;

export const StyledError = styled.span`
  ${tw`text-xs text-error mt-1`}
`;

export const RightAction = styled.button`
  ${tw`flex items-center gap-1`}
  ${tw`border-none bg-transparent cursor-pointer`}
  ${tw`h-5 text-xs text-blueGray-700`}
`;

export const ClearRightAction = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}) => (
  <RightAction type="button" onClick={onClick}>
    <Xmark />
  </RightAction>
);

const InputWrapper = ({
  shade = 'default',
  size = 'md',
  hasError = false,
  error,
  isFocused = false,
  isDisabled = false,
  isOCR = false,
  icon,
  rightEl,
  children,
  ...props
}: Props) => (
  <>
    <StyledWrapper
      {...props}
      $shade={shade}
      size={size}
      $hasError={!!error || hasError}
      $isFocused={isFocused}
      $isDisabled={isDisabled}
      $isOCR={isOCR}
      $hasLeftIcon={!!icon}
      $hasRightEl={!!rightEl}>
      {icon && <IconWrapper $hasError={!!error || hasError}>{icon}</IconWrapper>}
      {children}
      {isOCR && <Sparks />}
      {rightEl}
    </StyledWrapper>
    {error && <StyledError>{error}</StyledError>}
  </>
);

InputWrapper.RightAction = RightAction;
InputWrapper.ClearRightAction = ClearRightAction;

export default InputWrapper;
