import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ListSelect, DoubleCheck, FilterList } from 'iconoir-react';
import { camelCase } from '@lodash';
import { Configure } from 'react-instantsearch';
import tw from 'twin.macro';

import type { AlgoliaSettings } from '@hooks';
import { SearchBox } from '@new-components/InstantSearch';
import { SideBar, Switch, Button } from '@new-components';

import type { Tab, Mode } from '../types';
import { ViewTabs, ActionTabs, Modes } from '../constants';
import { SelectedHitsProvider, useSelectedHits } from '../contexts/SelectedHits';
import PostUiStateMiddleware from './PostUiStateMiddleware';
import UiStateProvider from './UiStateProvider';
import DropdownExport from './DropdownExport';
import ActionTab from './ActionTab';
import ViewTab from './ViewTab';
import Tabs from './Tabs';
import DropdownAdd from './DropdownAdd';
import BulkUpdateForm from './BulkUpdateForm';
import Filters from './Filters';

const mergeConfigure = ({ configure, ...other }: AlgoliaSettings['filters'], indexId: string) => {
  const filters = [configure, other[indexId]?.configure].filter(Boolean);
  return filters.length > 0 ? filters.join(' AND ') : undefined;
};

const TabLayout = ({
  settings,
  indexId,
  mode,
  children,
}: React.PropsWithChildren<{ settings: AlgoliaSettings; indexId: Tab; mode: Mode }>) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [displayFilters, setDisplayFilters] = useState(isDesktop);
  const { selectedIds, selectMany } = useSelectedHits();
  const hasSelected = selectedIds.length;

  const {
    displayAccountability,
    displayBillable,
    displayBusinessCodes,
    displayDriverCode,
    displayTags,
    displayLicensePlate,
  } = settings;

  const displayBulkUpdateForm = isDesktop && mode === 'view' && !!hasSelected;

  return (
    <>
      <div tw="flex flex-row items-center gap-2 p-4 md:(pl-8 py-6 pr-10) border-b border-blueGray-100">
        <Button
          shade="tertiary"
          leftIcon={<FilterList />}
          className={displayFilters ? 'active' : ''}
          onClick={() => setDisplayFilters(!displayFilters)}>
          {t('globals.filters')}
        </Button>
        <SearchBox placeholder={t('expenses.list.search_placeholder')} wait={500} />
        {mode === Modes.view && <DropdownExport />}
      </div>
      <div tw="flex flex-1 w-full">
        <div tw="flex min-h-0">
          <SideBar
            isOpen={displayFilters || displayBulkUpdateForm}
            onClose={() => (hasSelected ? selectMany([]) : setDisplayFilters(false))}
            title={displayBulkUpdateForm ? t('globals.bulk_action') : t('globals.filter_by')}>
            {mode === 'view' && (
              <BulkUpdateForm css={!displayBulkUpdateForm && tw`hidden`} settings={settings} />
            )}
            <Filters
              indexId={indexId}
              displayAccountability={displayAccountability}
              displayBillable={displayBillable}
              displayBusinessCodes={displayBusinessCodes}
              displayDriverCode={displayDriverCode}
              displayTags={displayTags}
              displayLicensePlate={displayLicensePlate}
              css={displayBulkUpdateForm && tw`hidden`}
            />
          </SideBar>
        </div>
        {children}
      </div>
    </>
  );
};

const TabsProvider = ({
  indexId,
  tabs,
  settings,
  mode,
}: {
  indexId: Tab;
  tabs: Tab[];
  settings: AlgoliaSettings;
  mode: Mode;
}) => {
  const { indexName, filters } = settings;
  const defaultFilters = filters[camelCase(indexId)]?.defaultFilters;
  const configure = mergeConfigure(filters, camelCase(indexId));

  const Tab = mode === Modes.action ? ActionTab : ViewTab;

  return (
    <>
      <Tabs current={indexId} tabs={tabs} />
      <UiStateProvider indexId={indexId} defaultFilters={defaultFilters}>
        {mode === Modes.action && <Configure filters={configure} />}
        <PostUiStateMiddleware indexName={indexName} indexId={indexId} />
        <SelectedHitsProvider indexId={indexId}>
          <TabLayout indexId={indexId} settings={settings} mode={mode}>
            <Tab indexId={indexId} />
          </TabLayout>
        </SelectedHitsProvider>
      </UiStateProvider>
    </>
  );
};

const actionTabs = Object.values(ActionTabs) as Tab[];
const viewTabs = Object.values(ViewTabs) as Tab[];

const Layout = ({ settings }: { settings: AlgoliaSettings }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch('/expenses/:slug');

  const slug = (match?.params.slug as Tab) || 'all';
  const mode = actionTabs.includes(slug) ? Modes.action : Modes.view;
  const canAction = settings.displayTabs?.some(tab => actionTabs.includes(tab as ActionTabs));

  const pathTabs = mode === 'action' ? actionTabs : viewTabs;
  const tabs = pathTabs.filter(tab => settings.displayTabs?.includes(tab));

  if (!tabs.includes(slug)) {
    setTimeout(() => navigate('/expenses/mine'), 250);
    return null;
  }

  const changeMode = (newMode: Mode) => {
    const aTabs = settings.displayTabs?.filter(tab => actionTabs.includes(tab as ActionTabs));

    if (newMode === Modes.action && aTabs.length) {
      navigate(`/expenses/${aTabs[0]}`);
      return;
    }

    if (newMode !== mode) navigate(`/expenses/${ViewTabs.all}`);
  };

  return (
    <>
      <div tw="flex items-center justify-between md:(px-10) py-6">
        <div tw="flex flex-1 justify-center">
          {canAction && (
            <Switch>
              <Switch.Item
                value={Modes.view}
                checked={mode === Modes.view}
                onChange={() => changeMode(Modes.view)}>
                <ListSelect />
                <span tw="uppercase">{t('expenses.list.view_tab')}</span>
              </Switch.Item>
              <Switch.Item
                value={Modes.action}
                checked={mode === Modes.action}
                onChange={() => changeMode(Modes.action)}>
                <DoubleCheck />
                <span tw="uppercase">{t('expenses.list.action_tab')}</span>
              </Switch.Item>
            </Switch>
          )}
        </div>
        <DropdownAdd tw="hidden md:block" />
      </div>
      <TabsProvider indexId={slug} settings={settings} tabs={tabs} mode={mode} />
    </>
  );
};

export default Layout;
