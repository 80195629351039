import React from 'react';
import { useStats } from 'react-instantsearch';
import { t } from 'i18next';
import 'twin.macro';

import { Button, Message } from '@new-components';

import { useSelectedHits } from '../contexts/SelectedHits';

const SelectAllHits = () => {
  const { nbHits } = useStats();
  const { selectedIds, allSelected, selectAll } = useSelectedHits();
  const nbSelected = selectedIds.length;

  if (nbSelected < 25 || allSelected || nbSelected === nbHits) return null;

  const nbAll = nbHits > 1000 ? '1000+' : nbHits;

  return (
    <div tw="p-2 border-t border-blueGray-100">
      <Message variant="info" tw="w-full">
        {t('expenses.list.page_all_selected', { nb: nbSelected })}
        <Button shade="link" tw="text-primary-2 text-xs ml-1" onClick={() => selectAll()}>
          {t('expenses.list.select_all', { nb: nbAll })}
        </Button>
      </Message>
    </div>
  );
};

export default SelectAllHits;
