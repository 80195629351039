import React from 'react';
import 'twin.macro';

import { useModal } from '@contexts/Modal';
import { Hits } from '@new-components/InstantSearch';
import { EmptyStateMessage } from '@new-components';

import { ActionHitsProvider } from '../contexts/ActionHits';
import {
  ErrorState,
  Hit,
  CorrectionRequestDrawer,
  ReportDrawer,
  CancelCorrectionRequestModal,
  CancelReversedExpenseModal,
  AddAttendeeModal,
} from '../components';
import BulkActionsBar from './BulkActionsBar';

const Modals = () => {
  const {
    state: { name, props },
    closeModal,
  } = useModal();

  return (
    <>
      {name === 'correctionRequest' && (
        <CorrectionRequestDrawer isOpen onClose={closeModal} {...props} />
      )}
      {name === 'report' && <ReportDrawer isOpen onClose={closeModal} {...props} />}
      {name === 'cancelCorrectionRequest' && (
        <CancelCorrectionRequestModal isOpen onClose={closeModal} {...props} />
      )}
      {name === 'cancelReport' && (
        <CancelReversedExpenseModal isOpen onClose={closeModal} {...props} />
      )}
      {name === 'addAttendee' && <AddAttendeeModal isOpen onClose={closeModal} {...props} />}
    </>
  );
};

const ExpensesToControl = () => (
  <>
    <div tw="flex-1">
      <ActionHitsProvider>
        <Hits
          head={null}
          hitComponent={Hit}
          emptyMessage={<EmptyStateMessage />}
          errorMessage={<ErrorState />}
        />
      </ActionHitsProvider>
    </div>
    <BulkActionsBar />
    <Modals />
  </>
);

export default ExpensesToControl;
