import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Layout as NewLayout } from '@new-components';
import { Layout as OldLayout } from '@components';

import { expenses, suppliers, companies, companyBankInformation, old } from './routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <NewLayout />,
    children: [...expenses, ...suppliers, ...companies, ...companyBankInformation],
  },
  // TODO: migrate old routes to new layout
  {
    path: '/',
    element: <OldLayout />,
    children: old,
  },
]);

export default router;
