import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  type PostCorrectionRequestParams,
  type PostReversedExpenseParams,
  type GetVatsParams,
  cancelCorrectionRequest,
  cancelReversedExpense,
  getExpense,
  getFieldsToDisplay,
  getValuesForAttribute,
  postCorrectionRequest,
  updateExpense,
  postReversedExpense,
  getVats,
} from '@api';

export const useGetExpense = (id: string, options = {}) =>
  useQuery(['expense', id], () => getExpense(id), options);

export const useUpdateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, params }: { id: string; params: ExpenseFormValues }) => updateExpense(id, params),
    { onSuccess: (data: Expense) => queryClient.setQueryData(['expense', data.id], data) },
  );
};

export const useGetFieldsToDisplay = (
  id: string,
  step: string,
  expenseCategoryId?: string,
  options = {},
) =>
  useQuery(
    ['expense', id, 'fieldsToDisplay', step, expenseCategoryId],
    () => getFieldsToDisplay(id, step, expenseCategoryId),
    options,
  );

export const useGetValuesForAttribute = (
  id: string,
  attribute: string | [string, any],
  query = '',
  options = {},
) =>
  useQuery(
    ['valuesForAttribute', id, attribute, query],
    () => getValuesForAttribute(id, { attribute, query }),
    options,
  );

export const usePostCorrectionRequest = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation((params: PostCorrectionRequestParams) => postCorrectionRequest(id, params), {
    onSuccess: () => queryClient.invalidateQueries(['expense', id]),
  });
};

export const useCancelCorrectionRequest = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => cancelCorrectionRequest(id), {
    onSuccess: () => queryClient.invalidateQueries(['expense', id]),
  });
};

export const usePostReport = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation((params: PostReversedExpenseParams) => postReversedExpense(id, params), {
    onSuccess: () => queryClient.invalidateQueries(['expense', id]),
  });
};

export const useCancelReversedExpense = (id: string, reversedId: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => cancelReversedExpense(reversedId), {
    onSuccess: () => queryClient.invalidateQueries(['expense', id]),
  });
};

export const useGetVats = (id: string | null, params: GetVatsParams, options = {}) =>
  useQuery(['expense', id, 'vats', params], () => getVats(id, params), {
    keepPreviousData: true,
    ...options,
  });
