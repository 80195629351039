import React from 'react';
import { InstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import 'twin.macro';

import { useAlgoliaSettings, useSiphonToAlgoliaQuery } from '@hooks';

import { paramsToIndexUiState } from './helpers';
import { Layout } from './components';

const Expenses = () => {
  const searchParams = new URLSearchParams(document.location.search);

  const { data: algolia } = useAlgoliaSettings();
  const { data: params, isFetching } = useSiphonToAlgoliaQuery(searchParams);

  if (!algolia || isFetching) return null;

  const searchClient = algoliasearch(algolia.appId, algolia.appKey);

  return (
    <InstantSearch
      indexName={algolia.indexName}
      searchClient={searchClient}
      initialUiState={{ [algolia.indexName]: paramsToIndexUiState(params) }}
      routing>
      <Layout settings={algolia} />
    </InstantSearch>
  );
};

export default Expenses;
