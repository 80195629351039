import React from 'react';
import { useTranslation } from 'react-i18next';
import { Send, WarningTriangle, Xmark } from 'iconoir-react';
import tw, { styled, theme } from 'twin.macro';

import { useModal } from '@contexts/Modal';
import {
  Button,
  ReceiptPreview,
  ContextField as Field,
  Toggle,
  TripOverview,
} from '@new-components';

import { FieldStates } from '../../constants';
import AccountingFieldset from './AccountingFieldset';
import DetailsFieldset, { DetailsFieldsetSkeleton } from './DetailsFieldset';
import VATFieldset, { VatFieldsetSkeleton } from './VATFieldset';
import LastCommentField from './LastCommentField';

const AccountingFieldsetSkeleton = DetailsFieldsetSkeleton;

export type Props = {
  hit: ExpenseHit;
};

const Vr = styled.div`
  ${tw`w-[1px] h-5 border-l border-solid border-l-blueGray-100`}
`;

type FormProps = {
  data: Expense & {
    at: Date;
    receiptStatus: ExpenseHit['receipt'];
    editLink: ExpenseHit['edit_link'];
    sourceType?: ExpenseHit['source_type'];
  };
  fields: Record<string, FieldStates>;
  // eslint-disable-next-line react/no-unused-prop-types
  vatDeductiblePercent?: number;
};

type ContentProps = FormProps & {
  isLoading: boolean;
};

const ContentSkeleton = () => (
  <>
    <hr />
    <div tw="flex flex-col flex-wrap gap-x-8 gap-y-4 py-4 sm:(flex-row)">
      <div tw="flex-1 flex justify-center">
        <ReceiptPreview src="" status="void" />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <DetailsFieldsetSkeleton />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <VatFieldsetSkeleton />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <AccountingFieldsetSkeleton />
      </div>
    </div>
  </>
);

const DefaultContentForm = ({ data, fields, vatDeductiblePercent }: FormProps) => {
  const { t } = useTranslation();

  const { lastComment } = data;

  return (
    <>
      <div tw="flex-1 flex flex-col items-center gap-y-4">
        <ReceiptPreview
          src={data.receiptUrl}
          status={data.receiptStatus}
          nbPages={data.receiptNbPages}
        />
        {(data.receiptStatus === 'lost' || data.receiptStatus === 'missing') && (
          <Field
            name="withoutReceipt"
            render={({ field }) => (
              <div tw="w-[276px] flex items-center justify-between">
                <span tw="text-sm">{t('file_lost_preview.lost_receipt')}</span>
                <Toggle {...field} checked={field.value} disabled={data.locked} />
              </div>
            )}
          />
        )}
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <DetailsFieldset expenseId={data.id} expenseAt={data.at} fields={fields} />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <VATFieldset expense={data} fields={fields} deductiblePercent={vatDeductiblePercent} />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <AccountingFieldset
          expenseId={data.id}
          analyticalAxes={data.analyticalAxes}
          fields={fields}
        />
        {lastComment && <LastCommentField lastComment={lastComment} tw="mt-4" />}
      </div>
    </>
  );
};

const KmContentForm = ({ data, fields }: FormProps) => {
  const { t } = useTranslation();

  const { lastComment } = data;

  return (
    <>
      <div tw="flex-1">
        <h5 tw="mb-2">{t('expenses.list.filters.trip')}</h5>
        <TripOverview
          startAddress={data.startPoint || ''}
          endAddress={data.endPoint || ''}
          distance={data.distance || 0}
          tw="w-[276px] pl-4 pb-4 pt-8"
        />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <DetailsFieldset expenseId={data.id} expenseAt={data.at} fields={fields || {}} />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        <AccountingFieldset
          expenseId={data.id}
          analyticalAxes={data.analyticalAxes}
          fields={fields}
        />
      </div>
      <div tw="flex-1 max-[1237px]:min-w-[276px]">
        {lastComment && (
          <LastCommentField
            lastComment={lastComment}
            tw="mt-0 sm:mt-8 min-[767px]:max-[929px]:mt-0"
          />
        )}
      </div>
    </>
  );
};

const ReportButton = ({ data }: { data: Expense }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <Button
      size="md"
      shade="warning"
      leftIcon={<WarningTriangle />}
      onClick={() => openModal('report', { expenseId: data.id, data })}>
      {t('expenses.list.table.report')}
    </Button>
  );
};

const CancelReportButton = ({
  expenseId,
  reversedExpenseId,
}: {
  expenseId: string;
  reversedExpenseId: string;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <Button
      size="md"
      shade="tertiary"
      leftIcon={<Xmark color={theme('colors.red.700')} />}
      onClick={() =>
        openModal('cancelReport', {
          expenseId,
          reversedExpenseId,
        })
      }>
      {t('expenses.list.table.cancel_report_request')}
    </Button>
  );
};

const CorrectionRequestButton = ({ expenseId }: { expenseId: string }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <Button
      size="md"
      shade="tertiary"
      leftIcon={<Send color={theme('colors.blue.100')} />}
      onClick={() => openModal('correctionRequest', { expenseId })}>
      {t('expenses.list.table.correction_request')}
    </Button>
  );
};

const CancelCorrectionRequestButton = ({ expenseId }: { expenseId: string }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <Button
      size="md"
      shade="tertiary"
      leftIcon={<Xmark color={theme('colors.red.700')} />}
      onClick={() => openModal('cancelCorrectionRequest', { expenseId })}>
      {t('expenses.list.table.cancel_correction_request')}
    </Button>
  );
};

const ContentForm = ({ isLoading, data, fields, vatDeductiblePercent }: ContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <hr />
      <div tw="flex flex-col flex-wrap gap-x-8 gap-y-4 py-4 sm:(flex-row)">
        {data.sourceType === 'KmExpense' ? (
          <KmContentForm data={data} fields={fields} />
        ) : (
          <DefaultContentForm
            data={data}
            fields={fields}
            vatDeductiblePercent={vatDeductiblePercent}
          />
        )}
      </div>
      <hr />
      <div tw="flex flex-col md:flex-row items-center py-4 justify-between gap-4">
        <div tw="flex flex-col md:flex-row items-center justify-between gap-4">
          <a href={`${data.editLink}?restore_page=1`} tw="text-sm link-primary font-semibold">
            {t('expenses.list.table.expense_details')}
          </a>
          {fields?.can_cancel_reversed_expense && (
            <>
              <Vr tw="hidden md:block" />
              <a
                href={`/reversed_expenses/${data.reversedExpenseId}/edit`}
                tw="text-sm link-primary text-red-500 font-semibold">
                {t('expenses.list.table.report_details')}
              </a>
            </>
          )}
        </div>
        <div tw="flex flex-col md:flex-row gap-4 w-full md:w-auto">
          {fields?.reversed_expense_creation_block && <ReportButton data={data} />}
          {fields?.can_cancel_reversed_expense && data.reversedExpenseId && (
            <CancelReportButton expenseId={data.id} reversedExpenseId={data.reversedExpenseId} />
          )}
          {fields?.can_create_correction_request && <CorrectionRequestButton expenseId={data.id} />}
          {fields?.can_cancel_correction_request && (
            <CancelCorrectionRequestButton expenseId={data.id} />
          )}
          <Button type="submit" size="md" shade="secondary" isLoading={isLoading}>
            {t('expenses.list.table.update')}
          </Button>
        </div>
      </div>
    </>
  );
};

const Content = ({ data, fields, vatDeductiblePercent, isLoading }: ContentProps) => {
  if (!data.id || !fields) return <ContentSkeleton />;

  return (
    <ContentForm
      isLoading={isLoading}
      data={data}
      fields={fields}
      vatDeductiblePercent={vatDeductiblePercent}
    />
  );
};

export default React.memo(Content);
