import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import Text from '../Text';
import {
  Error,
  ErrorContainer,
  LabelToolTipTextContainer,
  TextInput as StyledTextInput,
  TextInputContainer,
  TextInputLabelTooltipContainer,
  IconContainer,
  RequiredAsterisk,
} from './styled-components';
import Tooltip from '../Tooltip';

import { TextInputProps } from './types.d';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      onChange,
      onChangeText,
      onClick,
      label,
      displayLabel = true,
      placeholder,
      value,
      defaultValue,
      id,
      name,
      error,
      withError,
      withLabelTooltip = false,
      labelToolTipText,
      icon,
      isRequired = false,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(ref, inputRef);

    const errorMessage = useMemo(() => {
      if (error?.type === 'required') return t('globals.errors.required', { attribute: label });
      if (error?.type === 'duplicated') return t('globals.errors.duplicated');
      if (error?.type === 'pattern') return t('globals.errors.pattern', { attribute: label });
      return error?.message;
    }, [error, t, label]);

    const onInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e);
        }
        if (onChangeText) {
          onChangeText(e.target.value);
        }
      },
      [onChange, onChangeText],
    );

    const containerClicked = useCallback(() => {
      (combinedRef?.current as HTMLInputElement)?.click();
      (combinedRef?.current as HTMLInputElement)?.focus();
    }, [combinedRef]);

    const renderInput = useCallback(() => {
      if (icon) {
        return (
          <IconContainer hasError={!!error} onClick={containerClicked} ref={containerRef}>
            <StyledTextInput
              defaultValue={defaultValue}
              id={id}
              name={name}
              onChange={onInputChange}
              placeholder={placeholder}
              ref={combinedRef}
              value={value}
              onClick={onClick}
              required={isRequired}
            />
            <FontAwesomeIcon icon={icon} />
          </IconContainer>
        );
      }

      return (
        <StyledTextInput
          defaultValue={defaultValue}
          id={id}
          name={name}
          onChange={onInputChange}
          placeholder={placeholder}
          ref={combinedRef}
          value={value}
          hasError={!!error}
          onClick={onClick}
          required={isRequired}
        />
      );
    }, [
      icon,
      defaultValue,
      id,
      name,
      onInputChange,
      placeholder,
      combinedRef,
      value,
      error,
      onClick,
      containerClicked,
      isRequired,
    ]);

    if (withError || label) {
      return (
        <TextInputContainer>
          {label && displayLabel && !withLabelTooltip && <Text bold>{label}</Text>}
          {label && displayLabel && !withLabelTooltip && isRequired && (
            <RequiredAsterisk>
              <Text color="red"> *</Text>
            </RequiredAsterisk>
          )}
          {withLabelTooltip && displayLabel && (
            <TextInputLabelTooltipContainer>
              <Text bold>{label}</Text>
              {isRequired && (
                <RequiredAsterisk>
                  <Text color="red"> *</Text>
                </RequiredAsterisk>
              )}
              <LabelToolTipTextContainer>
                <Tooltip
                  anchor={<FontAwesomeIcon icon={faCircleQuestion} />}
                  place="top"
                  id={`${label}-tooltip`}>
                  <div className="multiline-tooltip">{labelToolTipText}</div>
                </Tooltip>
              </LabelToolTipTextContainer>
            </TextInputLabelTooltipContainer>
          )}
          {renderInput()}
          {withError && (
            <ErrorContainer>
              <Error>{errorMessage}</Error>
            </ErrorContainer>
          )}
        </TextInputContainer>
      );
    }

    return renderInput();
  },
);

TextInput.displayName = 'TextInput';
export default TextInput;
