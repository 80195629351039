import React from 'react';
import loadable from '@loadable/component';

import { paths } from './constants';

const AddBusinessCodeForm = loadable(() => import('../screens/business-codes/new'));
const BusinessCodesList = loadable(() => import('../screens/business-codes/list'));
const EditBusinessCodeForm = loadable(() => import('../screens/business-codes/edit'));
const AddAnalyticCodeForm = loadable(() => import('../screens/analytic-codes/new'));
const AnalyticCodesList = loadable(() => import('../screens/analytic-codes/list'));
const EditAnalyticCodeForm = loadable(() => import('../screens/analytic-codes/edit'));
const AddAnalyticalAxesForm = loadable(() => import('../screens/analytical-axes/new'));
const AnalyticalAxesList = loadable(() => import('../screens/analytical-axes/list'));
const EditAnalyticalAxesForm = loadable(() => import('../screens/analytical-axes/edit'));
const EditExpenseCategory = loadable(() => import('../screens/expense-categories/edit'));
const ExpenseCategoriesList = loadable(() => import('../screens/expense-categories/list'));
const ImportExpenseCategory = loadable(() => import('../screens/expense-categories/import'));
const NewExpenseCategory = loadable(() => import('../screens/expense-categories/new'));
const AccountsList = loadable(() => import('../screens/accounts/list'));
const AddDriverCodeForm = loadable(() => import('../screens/driver-codes/new'));
const DriverCodesList = loadable(() => import('../screens/driver-codes/list'));
const EditDriverCodeForm = loadable(() => import('../screens/driver-codes/edit'));
const CardOrdersList = loadable(() => import('../screens/card-orders/list'));
const CardPatternsList = loadable(() => import('../screens/card-patterns/list'));
const AddContactForm = loadable(() => import('../screens/contacts/new'));
const ContactsList = loadable(() => import('../screens/contacts/list'));
const EditContactForm = loadable(() => import('../screens/contacts/edit'));
const Dashboard = loadable(() => import('../screens/dashboard'));
const DepartmentsList = loadable(() => import('../screens/departments/list'));
const EditExpenseExportTemplate = loadable(
  () => import('../screens/expense-export-templates/edit'),
);
const ExpenseExportTemplatesList = loadable(
  () => import('../screens/expense-export-templates/list'),
);
const NewExpenseExportTemplate = loadable(() => import('../screens/expense-export-templates/new'));
const EmployeesList = loadable(() => import('../screens/employees/list'));
const PaymentErrorsList = loadable(() => import('../screens/payment-errors/list'));
const ShowExpenseExportTemplate = loadable(
  () => import('../screens/expense-export-templates/show'),
);
const VatRatesList = loadable(() => import('../screens/vat-rates/list'));
const TemporaryCardDeactivationRulesList = loadable(
  () => import('../screens/temporary-card-deactivation-rules/list'),
);
const TemporaryCardDeactivationRulesEdit = loadable(
  () => import('../screens/temporary-card-deactivation-rules/edit'),
);
const TemporaryCardDeactivationRulesNew = loadable(
  () => import('../screens/temporary-card-deactivation-rules/new'),
);

const routes = [
  { path: paths.DASHBOARD, element: <Dashboard /> },
  { path: paths.ACCOUNTS, element: <AccountsList /> },
  { path: paths.ANALYTICAL_AXES, element: <AnalyticalAxesList /> },
  { path: paths.NEW_ANALYTICAL_AXE, element: <AddAnalyticalAxesForm /> },
  { path: paths.EDIT_ANALYTICAL_AXE, element: <EditAnalyticalAxesForm /> },
  { path: paths.ANALYTIC_CODES, element: <AnalyticCodesList /> },
  { path: paths.NEW_ANALYTIC_CODE, element: <AddAnalyticCodeForm /> },
  { path: paths.EDIT_ANALYTIC_CODE, element: <EditAnalyticCodeForm /> },
  { path: paths.BUSINESS_CODES, element: <BusinessCodesList /> },
  { path: paths.NEW_BUSINESS_CODE, element: <AddBusinessCodeForm /> },
  { path: paths.EDIT_BUSINESS_CODE, element: <EditBusinessCodeForm /> },
  { path: paths.CARD_ORDERS, element: <CardOrdersList /> },
  { path: paths.CARD_PATTERNS, element: <CardPatternsList /> },
  { path: paths.CONTACTS, element: <ContactsList /> },
  { path: paths.EDIT_CONTACT, element: <EditContactForm /> },
  { path: paths.NEW_CONTACT, element: <AddContactForm /> },
  { path: paths.DEPARTMENTS, element: <DepartmentsList /> },
  { path: paths.DRIVER_CODES, element: <DriverCodesList /> },
  { path: paths.NEW_DRIVER_CODE, element: <AddDriverCodeForm /> },
  { path: paths.EDIT_DRIVER_CODE, element: <EditDriverCodeForm /> },
  { path: paths.EMPLOYEES, element: <EmployeesList /> },
  { path: paths.EXPENSE_CATEGORIES, element: <ExpenseCategoriesList /> },
  { path: paths.NEW_EXPENSE_CATEGORY, element: <NewExpenseCategory /> },
  { path: paths.EDIT_EXPENSE_CATEGORY, element: <EditExpenseCategory /> },
  { path: paths.IMPORT_EXPENSE_CATEGORIES, element: <ImportExpenseCategory /> },
  { path: paths.VAT_RATES, element: <VatRatesList /> },
  { path: paths.EXPENSE_EXPORT_TEMPLATES, element: <ExpenseExportTemplatesList /> },
  { path: paths.NEW_EXPENSE_EXPORT_TEMPLATE, element: <NewExpenseExportTemplate /> },
  { path: paths.PAYMENT_ERRORS, element: <PaymentErrorsList /> },
  { path: paths.EDIT_EXPENSE_EXPORT_TEMPLATE, element: <EditExpenseExportTemplate /> },
  { path: paths.EXPENSE_EXPORT_TEMPLATE, element: <ShowExpenseExportTemplate /> },
  {
    path: paths.TEMPORARY_CARD_DEACTIVATION_RULES,
    element: <TemporaryCardDeactivationRulesList />,
  },
  {
    path: paths.NEW_TEMPORARY_CARD_DEACTIVATION_RULE,
    element: <TemporaryCardDeactivationRulesNew />,
  },
  {
    path: paths.EDIT_TEMPORARY_CARD_DEACTIVATION_RULE,
    element: <TemporaryCardDeactivationRulesEdit />,
  },
];

export default routes;
