import React from 'react';
import { useTranslation } from 'react-i18next';
import 'twin.macro';

import { ContextField as Field, Select, Label, InputText, Skeleton } from '@new-components';
import { FieldStates } from '../../constants';
import { loadOptions } from './utils';
import KmFieldset from './KmFieldset';
import AttendeesFields from './AttendeesFields';

type FieldProps = {
  expenseId: string;
};

type Props = React.ComponentPropsWithoutRef<'fieldset'> &
  FieldProps & {
    fields: Record<string, FieldStates>;
    expenseAt: Date;
  };

const TitleField = ({ required }: { required?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label tw="mb-1" hasRequired={required}>
        {t('expenses.list.table.title')}
      </Label>
      <Field name="title" label={t('expenses.list.table.title')} required={required}>
        <InputText />
      </Field>
    </div>
  );
};

const ExpenseCategoryField = ({ expenseId, disabled }: FieldProps & { disabled?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label hasRequired tw="mb-1">
        {t('expenses.list.filters.expense_category')}
      </Label>
      <Field name="expenseCategory" label={t('expenses.list.filters.expense_category')} required>
        <Select loadOptions={loadOptions(expenseId, 'expense_category')} disabled={disabled} />
      </Field>
    </div>
  );
};

const DepartmentField = ({ expenseId }: FieldProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label tw="mb-1">{t('expenses.list.filters.department')}</Label>
      <Field name="department" label={t('expenses.list.filters.department')}>
        <Select loadOptions={loadOptions(expenseId, 'department')} />
      </Field>
    </div>
  );
};

const props = (state: FieldStates) => ({
  required: state === FieldStates.required,
  disabled: state === FieldStates.disabled,
});

const DetailsFieldset = ({ expenseId, fields, expenseAt }: Props) => {
  const { t } = useTranslation();

  return (
    <fieldset>
      <h5 tw="mb-2">{t('expenses.list.filters.details')}</h5>
      <div tw="flex flex-col gap-y-4">
        {fields.title && <TitleField {...props(fields.title)} />}
        {fields.expense_category && (
          <ExpenseCategoryField expenseId={expenseId} {...props(fields.expense_category)} />
        )}
        {fields.department && (
          <DepartmentField expenseId={expenseId} {...props(fields.department)} />
        )}
        <KmFieldset expenseId={expenseId} fields={fields} />
        <AttendeesFields activeAt={expenseAt} fields={fields} />
      </div>
    </fieldset>
  );
};

export const DetailsFieldsetSkeleton = () => (
  <div>
    <Skeleton tw="w-32 h-6 mb-2" />
    <div tw="flex flex-col gap-y-4">
      <div>
        <Skeleton tw="w-28 h-3.5 mb-1" />
        <Skeleton tw="w-[90%] h-10" />
      </div>
      <div>
        <Skeleton tw="w-28 h-3.5 mb-1" />
        <Skeleton tw="w-full h-10" />
      </div>
      <div>
        <Skeleton tw="w-28 h-3.5 mb-1" />
        <Skeleton tw="w-full h-10" />
      </div>
    </div>
  </div>
);

export default DetailsFieldset;
