import React from 'react';
import { useTranslation } from 'react-i18next';
import 'twin.macro';

import { Label, InputText } from '@new-components';

const LastCommentField = ({ lastComment, ...props }: { lastComment?: Expense['lastComment'] }) => {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <Label tw="mb-1">{t('expenses.list.form.comment.label')}</Label>
      <InputText disabled readOnly value={lastComment} />
    </div>
  );
};

export default LastCommentField;
