import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import tw from 'twin.macro';

import { Euro, NavArrowDown } from 'iconoir-react';

import { DEFAULT_CURRENCY } from '@constants';
import { useGetVats } from '@hooks/useExpenses';
import {
  Select,
  Option,
  InputText,
  RadioButtonGroup,
  ContextField as Field,
  Dropdown,
  Message,
  Skeleton,
} from '@new-components';

import { FieldStates } from '../../../constants';
import { fieldProps } from '../utils';

export enum CountryGroup {
  local = 'local',
  intracom = 'intracom',
  outsideEU = 'outside_eu',
}

enum Taxes {
  included = 'included',
  excluded = 'excluded',
}

enum VatRateOptions {
  none = 'none',
  multiple = 'multiple',
}

const calcVatAmount = (
  rate: number,
  tax: Taxes,
  {
    amount,
    amountFees,
    amountTouristTax,
  }: {
    amount: number;
    amountFees: number;
    amountTouristTax: number;
  },
) => {
  const reductions = amountFees + amountTouristTax;
  const amountWithReductions = amount - reductions;

  if (tax === Taxes.excluded) return +((amountWithReductions * rate) / 100).toFixed(2);
  return +(amountWithReductions - amountWithReductions / (1 + rate / 100)).toFixed(2);
};

const AmountTouristTax = () => {
  const { t } = useTranslation();

  return (
    <div>
      <label tw="block text-label mb-1">{t('expenses.list.form.tourist_tax.label')}</label>
      <Field name="amountTouristTax" valueAsNumber>
        <InputText
          id="amountTouristTax"
          type="number"
          placeholder="0"
          tw="w-full"
          rightEl={<Euro />}
        />
      </Field>
    </div>
  );
};

const VatNumberField = () => {
  const { t } = useTranslation();

  return (
    <div>
      <label tw="block text-label mb-1">{t('expenses.list.form.vat_number.label')}</label>
      <Field name="vatNumber">
        <InputText type="text" placeholder={t('globals.enter_here')} tw="w-full" />
      </Field>
    </div>
  );
};

const VatCountryGroupField = ({
  localCountry,
  disabled,
}: {
  localCountry: string;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const options = [
    { value: CountryGroup.local, label: localCountry },
    { value: CountryGroup.intracom, label: t('expenses.list.form.country_group.intracom') },
    { value: CountryGroup.outsideEU, label: t('expenses.list.form.country_group.outsideEU') },
  ];

  return (
    <Field
      name="countryGroup"
      render={({ field }) => (
        <RadioButtonGroup>
          {options.map(({ value, label }) => (
            <RadioButtonGroup.Radio
              key={value}
              name={field.name}
              value={value}
              color="blue"
              disabled={disabled}
              checked={field.value === value}
              onChange={field.onChange}>
              {label}
            </RadioButtonGroup.Radio>
          ))}
        </RadioButtonGroup>
      )}
    />
  );
};

const VatCountryFields = ({
  localCountry,
  countries,
  patterns,
  disabled = false,
}: {
  localCountry: Expense['localCountry'];
  countries: Expense['countries'];
  patterns: Expense['countryVatPatterns'];
  disabled?: boolean;
}) => {
  const isRendered = React.useRef(false);
  const {
    getValues,
    setValue,
    formState: { isDirty },
  } = useFormContext();

  const alpha2 = getValues('vatNumber')?.substring(0, 2);
  const match = patterns.find(v => v.alpha2 === alpha2);

  const isDisabled = !!match || disabled;

  const [countryGroup, invoiceCountry] = getValues(['countryGroup', 'invoiceCountry']);

  useEffect(() => {
    if (!isDirty) return;

    if (countryGroup === CountryGroup.local && invoiceCountry !== localCountry.value) {
      setValue('invoiceCountry', localCountry.value);
    } else if (countryGroup === CountryGroup.intracom && invoiceCountry !== 'EUE') {
      setValue('invoiceCountry', 'EUE');
    } else if (countryGroup === CountryGroup.outsideEU && invoiceCountry !== 'XXX') {
      setValue('invoiceCountry', 'XXX');
    }
  }, [countryGroup]);

  useEffect(() => {
    if (invoiceCountry === localCountry.value) {
      setValue('countryGroup', CountryGroup.local);
      setValue('vatTax', Taxes.included);
    } else {
      if (invoiceCountry === 'EUE' || invoiceCountry === 'XXX') {
        setValue('countryGroup', CountryGroup.intracom);
        setValue('vatTax', Taxes.excluded);
      }
      if (invoiceCountry === 'XXX') setValue('countryGroup', CountryGroup.outsideEU);
    }
  }, [invoiceCountry]);

  useEffect(() => {
    if (match) setValue('invoiceCountry', match.alpha3);
  }, [match]);

  useEffect(() => {
    isRendered.current = true;
  }, []);

  return (
    <div tw="flex flex-col gap-y-2.5">
      <div>
        <label tw="block text-label mb-1">&nbsp;</label>
        <VatCountryGroupField localCountry={localCountry.label} disabled={isDisabled} />
      </div>
      {countryGroup === CountryGroup.intracom && (
        <Field
          name="invoiceCountry"
          render={({ field }) => (
            <Select
              disabled={isDisabled}
              options={countries}
              value={countries.find((c: Option) => c.value === field.value)}
              onChange={v => field.onChange((v as Option).value)}
            />
          )}
        />
      )}
    </div>
  );
};

const VatTaxField = () => {
  const { t } = useTranslation();
  const options = [
    { value: Taxes.included, label: t('expenses.list.form.vat_tax.included') },
    { value: Taxes.excluded, label: t('expenses.list.form.vat_tax.excluded') },
  ];

  return (
    <Field
      name="vatTax"
      render={({ field }) => (
        <Select
          options={options}
          value={options.find(o => o.value === field.value) || options[0]}
          onChange={v => field.onChange((v as Option).value)}
        />
      )}
    />
  );
};

const VatRateId = ({
  field,
  options,
}: {
  field: any;
  options: (Option & { disabled: boolean })[];
}) => {
  const { t } = useTranslation();
  const option = options.find(o => o.value === field.value) || options[0];

  return (
    <>
      <RadioButtonGroup tw="max-[1426px]:hidden">
        {options.map(({ value, label, disabled }) => (
          <RadioButtonGroup.Radio
            key={value}
            value={value}
            name={field.name}
            color="blue"
            disabled={disabled}
            checked={field.value === value}
            onChange={field.onChange}>
            {label || t(`expenses.list.form.vat_rate.${value}`)}
          </RadioButtonGroup.Radio>
        ))}
      </RadioButtonGroup>
      <Dropdown
        tw="hidden max-[1426px]:block"
        button={
          <Dropdown.Button shade="tertiary" rightIcon={<NavArrowDown />} tw="w-full">
            {option.label || t(`expenses.list.form.vat_rate.${option.value}`)}
          </Dropdown.Button>
        }>
        <Dropdown.SubMenu>
          {options.map(({ value, label, disabled }) => (
            <Dropdown.SubMenu.Item
              key={value}
              value={value}
              disabled={disabled}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                field.onChange(value);
              }}>
              {label || t(`expenses.list.form.vat_rate.${value}`)}
            </Dropdown.SubMenu.Item>
          ))}
        </Dropdown.SubMenu>
      </Dropdown>
    </>
  );
};

const VatSanityCheck = ({
  amount,
  amountFees,
  amountTouristTax,
  vatSensibilityThreshold,
}: {
  amount: number;
  amountFees: number;
  amountTouristTax: number;
  vatSensibilityThreshold: number;
}) => {
  const { t } = useTranslation();

  const vats = useWatch({ name: 'vats' });
  const tax = useWatch({ name: 'vatTax' });

  const reductions = amountFees + amountTouristTax;
  const amountWithReductions = amount - reductions;

  const totalVatAmount = vats.reduce((acc: number, vat: { amount: number }) => acc + vat.amount, 0);

  if (totalVatAmount === 0 || tax === Taxes.excluded) return null;

  const totalSanityCheckAmount = vats.reduce(
    (acc: number, vat: VAT) => acc + (vat.amount / vat.rate) * 100,
    0,
  );

  const diff = amountWithReductions - Math.abs(totalSanityCheckAmount) - Math.abs(totalVatAmount);

  if (Math.abs(diff) <= vatSensibilityThreshold || diff > 0) return null;

  return (
    <Message variant="warning" size="sm">
      {t('expenses.list.form.vat_sanity_check_failed')}
    </Message>
  );
};

const VatRateFields = ({
  initialId,
  vats,
  disabledMultiple,
  showDeductibleAmount,
  deductiblePercent,
}: {
  initialId: string;
  vats: VAT[];
  disabledMultiple: boolean;
  showDeductibleAmount: boolean;
  deductiblePercent: number;
}) => {
  const { t } = useTranslation();
  const { getValues, register } = useFormContext();

  const currentId = getValues('vatRateId');
  const options = vats.reduce<(Option & { disabled: boolean })[]>((acc, vat) => {
    if (vat.superlow && initialId !== vat.vatRateId) return [...acc];
    return [...acc, { label: vat.name, value: vat.vatRateId, disabled: vat.disabled }];
  }, []);

  if (!options.length) return null;

  options.unshift({ label: null, value: VatRateOptions.none, disabled: false });
  options.push({
    label: '+',
    value: VatRateOptions.multiple,
    disabled: disabledMultiple && initialId !== VatRateOptions.multiple,
  });

  return (
    <>
      <Field
        name="vatRateId"
        render={({ field }) => <VatRateId field={field} options={options} />}
      />
      {vats
        .map((vat, i) => {
          const value = getValues(`vats[${i}][amount]`) || 0;
          const absValue = value ? Math.abs(value) : 0;

          return (
            <div
              key={vat.id}
              css={[!(currentId === 'multiple' || currentId === vat.vatRateId) && tw`hidden`]}>
              <label htmlFor={`vats.${i}.amount`} tw="block text-label mb-1">
                {t('expenses.list.form.vat.label', { name: vat.name })}
              </label>{' '}
              <input type="hidden" {...register(`vats.${i}.vatRateId`)} value={vat.vatRateId} />
              <input type="hidden" {...register(`vats.${i}.rate`)} value={vat.rate} />
              <Field name={`vats.${i}.amount`} defaultValue="" valueAsNumber>
                <InputText type="number" step={0.01} rightEl={<Euro />} />
              </Field>
              {showDeductibleAmount && absValue !== 0 && (
                <span tw="block text-label font-normal italic text-right mt-2">
                  {t('expenses.list.form.vat.including_deductible', {
                    amount: Math.abs((value * deductiblePercent) / 100).toFixed(2),
                    currency: DEFAULT_CURRENCY,
                  })}
                </span>
              )}
            </div>
          );
        })
        .reverse()}
    </>
  );
};

const VatFieldset = ({
  expense,
  fields,
  deductiblePercent,
}: React.ComponentPropsWithoutRef<'fieldset'> & {
  expense: Expense;
  fields: Record<string, FieldStates>;
  deductiblePercent?: number;
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { isDirty },
  } = useFormContext();
  const { fields: vatFields, replace } = useFieldArray({ control, name: 'vats' });

  const { isVatRecoveryEU, isVatReverseChargeEU } = expense;
  const amount = Math.abs(expense.amount);
  const amountFees = Math.abs(expense.amountFees);

  const [countryGroup, invoiceCountry, tax, rateId, amountTouristTax] = watch([
    'countryGroup',
    'invoiceCountry',
    'vatTax',
    'vatRateId',
    'amountTouristTax',
  ]);

  const { data: newVats } = useGetVats(
    expense.id,
    {
      companyCountry: expense.localCountry.value,
      invoiceCountry,
      vatTax: tax,
    },
    { enabled: !!invoiceCountry && invoiceCountry !== expense.invoiceCountry },
  );

  const vats = newVats || expense.vats;

  useEffect(() => {
    if (!isDirty) return;

    if (rateId === VatRateOptions.multiple) replace(vats);
    else {
      const recalcVats = vats.map(vat => {
        if (rateId === vat.vatRateId) {
          const calc = calcVatAmount(vat.rate, tax, { amount, amountFees, amountTouristTax });
          return { ...vat, amount: calc };
        }
        return { ...vat, amount: 0 };
      });

      replace(recalcVats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateId, tax, amountTouristTax]);

  return (
    <fieldset {...fieldProps(fields.vat_block)} className="group">
      <h5 tw="mb-2">{t('vat_rates.list.title')}</h5>
      <div tw="flex flex-col gap-y-4">
        {fields.amount_tourist_tax && <AmountTouristTax />}
        <VatNumberField />
        <div tw="flex flex-col gap-y-2.5">
          <VatCountryFields
            localCountry={expense.localCountry}
            countries={expense.countries}
            patterns={expense.countryVatPatterns}
            disabled={expense.isReversedExpense}
          />
          {!(
            countryGroup !== CountryGroup.intracom ||
            invoiceCountry === 'EUE' ||
            (!isVatRecoveryEU && !isVatReverseChargeEU)
          ) && <VatTaxField />}
          <VatSanityCheck
            amount={amount}
            amountFees={amountFees}
            amountTouristTax={amountTouristTax}
            vatSensibilityThreshold={expense.vatSensibilityThreshold}
          />
          <VatRateFields
            initialId={expense.vatRateId}
            vats={vatFields as unknown as VAT[]}
            disabledMultiple={expense.isReversedExpense}
            showDeductibleAmount={invoiceCountry === expense.localCountry.value}
            deductiblePercent={deductiblePercent ?? expense.vatDeductiblePercent}
          />
        </div>
      </div>
    </fieldset>
  );
};

export const VatFieldsetSkeleton = () => (
  <div>
    <Skeleton tw="w-24 h-6 mb-2" />
    <div tw="flex flex-col gap-y-4">
      <div>
        <Skeleton tw="w-28 h-3.5 mb-1" />
        <Skeleton tw="w-[90%] h-10" />
      </div>
      <div>
        <div tw="h-3.5 mb-1" />
        <Skeleton tw="w-full h-10 mb-2.5" />
        <Skeleton tw="w-full h-10" />
      </div>
    </div>
  </div>
);

export default VatFieldset;
