import React, { useEffect, useRef, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useSearchParams } from 'react-router-dom';

import type { Tab } from '../types';

const UiStateProvider = ({
  indexId,
  defaultFilters,
  children,
}: React.PropsWithChildren<{ indexId: Tab; defaultFilters: {} }>) => {
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const [prev, setPrev] = useState(indexId);
  const [uiState, setUiState] = useState<{ [K in Tab]: {} }>({
    all: {},
    card: {},
    mine: {},
    external: {},
    employee: {},
    km: {},
    supplier: {},
    to_control: {},
    to_approve: {},
  });

  const [appliedTo, setAppliedTo] = useState<Tab[]>([]);
  const [queryParameters] = useSearchParams();

  const isRendered = useRef(false);

  useEffect(() => {
    if (prev !== indexId) {
      setPrev(indexId);
      setIndexUiState(uiState[indexId]);
    }

    if (!!defaultFilters && !appliedTo.includes(indexId)) {
      setAppliedTo([...appliedTo, indexId]);
      if ((queryParameters.size === 0 && !isRendered.current) || prev !== indexId)
        setIndexUiState({ refinementList: defaultFilters });
    }

    isRendered.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexId]);

  useEffect(() => {
    setUiState({ ...uiState, [indexId]: indexUiState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexId, indexUiState]);

  return children;
};

export default UiStateProvider;
