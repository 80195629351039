import React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import { useAlgoliaSettings } from '@hooks';
import { Tr, Td, Checkbox } from '@new-components';

import { useSelectedHits } from '../contexts/SelectedHits';

import Data from './Data';
import ReceiptTag from './ReceiptTag';
import StepTag from './StepTag';
import TdAmount from './TdAmount';

export type Props = {
  hit: ExpenseHit;
};

const Hit = ({ hit }: Props) => {
  const { t } = useTranslation();
  const { data: settings } = useAlgoliaSettings();
  const { isSelected, toggleSelect, allSelected } = useSelectedHits();
  const isHitSelected = isSelected(hit) || allSelected;

  const isIncoming = !hit.settled && hit.type === 'debit';

  return (
    <Tr isSelected={isHitSelected} css={isIncoming && tw`text-blueGray-800`}>
      <MediaQuery minWidth={768}>
        <Td tw="w-12 max-w-12 p-1.5" aria-label="Select">
          <Checkbox
            as="span"
            checked={isHitSelected}
            readOnly
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              e.stopPropagation();
              toggleSelect(hit);
            }}
          />
        </Td>
      </MediaQuery>
      <Td tw="text-blueGray-800 w-28 max-w-28" href={`${hit.edit_link}?restore_page=1`}>
        {t('{{date, human}}', { date: new Date(hit.date * 1000) })}
      </Td>
      <Td tw="font-semibold w-36 max-w-36 truncate" href={`${hit.edit_link}?restore_page=1`}>
        {hit.user_name}
      </Td>
      <Td tw="w-52 max-w-52 truncate" href={`${hit.edit_link}?restore_page=1`}>
        <Data fallback={hit.merchant_name}>{hit.title}</Data>
      </Td>
      <Td
        aria-label={hit.expense_category}
        tw="w-36 max-w-36 truncate"
        href={`${hit.edit_link}?restore_page=1`}>
        <Data fallback={t('globals.undefined')}>{hit.expense_category}</Data>
      </Td>
      {settings?.displayLicensePlate && (
        <Td
          aria-label={hit.license_plate}
          tw="w-24 max-w-24"
          href={`${hit.edit_link}?restore_page=1`}>
          <Data>{hit.license_plate}</Data>
        </Td>
      )}
      <Td aria-label={hit.receipt} tw="w-24 max-w-24" href={`${hit.edit_link}?restore_page=1`}>
        <ReceiptTag value={hit.receipt} />
      </Td>
      <Td aria-label={hit.step} tw="w-24 max-w-24" href={`${hit.edit_link}?restore_page=1`}>
        <StepTag value={hit.step} />
      </Td>
      <TdAmount hit={hit} isIncoming={isIncoming} />
    </Tr>
  );
};

export default Hit;
