import { toggleAttributes, rangeAttributes, refinementListAttributes } from './constants';

type IndexUiState = {
  query?: string;
  range?: { [attribute: string]: any };
  refinementList?: { [attribute: string]: any };
  toggle?: { [attribute: string]: any };
  page?: number;
  hitsPerPage?: number;
};

export const paramsToIndexUiState = (params?: Record<string, any>): IndexUiState => {
  const state: IndexUiState = {
    range: {},
    refinementList: {},
    toggle: {},
    page: 1,
    hitsPerPage: 25,
  };

  toggleAttributes.forEach(key => {
    if (params?.[key] && state.toggle) state.toggle[key] = params[key];
  });

  rangeAttributes.forEach(key => {
    if (params?.[key] && state.range) state.range[key] = params[key];
  });

  refinementListAttributes.forEach(key => {
    if (params?.[key] && state.refinementList) state.refinementList[key] = params[key];
  });

  if (params?.page) state.page = params.page;
  if (params?.hits_per_page) state.hitsPerPage = params.hits_per_page;
  // eslint-disable-next-line prefer-destructuring
  if (params?.query?.[0]) state.query = params.query[0];

  return state;
};
