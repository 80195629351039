import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageFlip, WarningCircle } from 'iconoir-react';
import tw, { styled } from 'twin.macro';

import BadgeIcon from '../BadgeIcon';
import Button from '../Button';

export type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const FieldWrapper = styled.div`
  ${tw`relative w-full h-full flex flex-col items-center justify-center p-6`}
  ${tw`border border-gray-300 rounded-lg`}
  ${tw`text-xs text-center border border-dashed border-red-500 bg-red-005`}
`;

const IconWrapper = styled.div`
  ${tw`
    relative flex justify-center
    text-red-700
    pb-3
  `}
`;

const FileLoadError = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <FieldWrapper>
      <IconWrapper>
        <PageFlip tw="w-[56px] h-[56px]" />
        <BadgeIcon size="sm" color="red" tw="absolute -top-[3px] -right-[3px]">
          <WarningCircle />
        </BadgeIcon>
      </IconWrapper>
      <span tw="text-red-700 pb-6">{t('file_load_error.check_format_file')}</span>
      {!!onClick && (
        <>
          <span tw="text-blue-700 pb-0.5 font-semibold">{t('file_load_error.submit_receipt')}</span>
          <Button shade="link" tw="text-blue-200" onClick={onClick}>
            {t('file_load_error.click_here')}
          </Button>
        </>
      )}
      <span tw="text-blueGray-700 pb-2">{t('file_load_error.format')}</span>
    </FieldWrapper>
  );
};

export default FileLoadError;
