import React from 'react';
import tw, { styled } from 'twin.macro';
import { Check, Minus } from 'iconoir-react';

export type Props = React.ComponentPropsWithoutRef<'input'> & {
  isMaster?: boolean;
  as?: React.ElementType;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};

const CheckboxContainer = styled.label`
  ${tw`inline-block align-middle w-4.5 h-4.5`}

  /* fix position */
  td > &, th > & {
    ${tw`block box-content p-1`}
  }
`;

const StyledCheckbox = styled.span<{ $isMaster: boolean }>`
  ${tw`block align-middle bg-white cursor-pointer h-full`}
  ${tw`border rounded border-blueGray-100 hover:border-blue-050`}
  ${tw`[> svg]:(w-4 h-4 invisible)`}

  input:checked + & {
    ${tw`bg-blue-500 border-blue-500 text-white`}
    ${({ $isMaster }) => $isMaster && tw`text-blue-200 bg-blue-050 border-blue-050`}
    ${tw`[> svg]:visible`}
  }

  input:disabled + & {
    ${tw`border-gray-300 bg-blueGray-050 text-gray-300 cursor-not-allowed`}
    ${({ $isMaster }) => $isMaster && tw`text-gray-300 bg-gray-300 border-gray-300`}
  }
`;

const HiddenCheckbox = styled.input`
  ${tw`hidden`}
`;

const Checkbox = ({ isMaster = false, as = 'label', onClick, ...props }: Props) => (
  <CheckboxContainer onClick={onClick} as={as}>
    <HiddenCheckbox {...props} type="checkbox" />
    <StyledCheckbox $isMaster={isMaster}>{isMaster ? <Minus /> : <Check />}</StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
