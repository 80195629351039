import React from 'react';
import tw, { styled } from 'twin.macro';

import { useTranslation } from 'react-i18next';
import { useInstantSearch, useStats } from 'react-instantsearch';

type Props = {
  i18nKey: string;
};

const StyledStats = styled.span`
  ${tw`text-sm leading-6 font-semibold whitespace-nowrap`}
`;

const Stats = ({ i18nKey }: Props) => {
  const { t } = useTranslation();
  const { status } = useInstantSearch();
  const { nbHits } = useStats();

  return (
    <StyledStats>
      {status === 'idle' &&
        (nbHits > 1000 ? t('expenses.list.thousand_results') : t(i18nKey, { count: nbHits }))}
      {(status === 'loading' || status === 'stalled') && t('expenses.list.loading_results')}
    </StyledStats>
  );
};

export default Stats;
