import React, { PropsWithChildren, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { NavArrowDown, Xmark } from 'iconoir-react';

import { StyledOverlay } from '../Drawer';

export type Props = React.ComponentPropsWithoutRef<'div'> & {
  title: string;
  isOpen?: boolean;
  onClose?: () => void;
};

export type ItemProps = React.ComponentProps<'div'> & {
  className?: string | string[];
  canOpen?: boolean;
};

export type ItemTitleProps = PropsWithChildren<{
  rightEl?: React.ReactNode;
}>;

const StyledSideBarContainer = styled.div<{ $isOpen: boolean }>`
  ${tw`fixed z-50 pt-4 inset-0 h-full w-full`}
  ${tw`md:(static pt-0 top-0 bottom-0 w-72)`}
  ${({ $isOpen }) => !$isOpen && tw`!bottom-0 translate-y-full md:hidden`}
`;

const StyledSideBar = styled.div`
  ${tw`relative bg-blue-005 h-full overflow-scroll rounded-lg md:rounded-none`}
  background: radial-gradient(
    circle at bottom -10rem left -7rem,
    ${theme`colors.blue.010`} 30%,
    ${theme`colors.blue.005`} 30%
  );
`;

const StyledTitle = tw.div`
  sticky top-0 left-0 bg-blue-005
  flex justify-between items-center
  py-3.5 pl-8 pr-6
  border-b border-blueGray-100
  text-sm font-semibold
`;

const CloseButton = tw.button`
  border-none bg-transparent cursor-pointer
`;

const SideBar = ({ children, title, isOpen = true, onClose = () => null, ...props }: Props) => (
  <>
    <StyledOverlay $isOpen={isOpen} onClick={onClose} tw="md:hidden" />
    <StyledSideBarContainer $isOpen={isOpen} {...props}>
      <StyledSideBar>
        <StyledTitle>
          {title}
          <CloseButton type="button" onClick={onClose}>
            <Xmark />
          </CloseButton>
        </StyledTitle>
        <div>{children}</div>
      </StyledSideBar>
    </StyledSideBarContainer>
  </>
);

const ItemContainer = styled.div<{ $isOpen?: boolean }>`
  ${tw`pl-8 pr-6`}
  ${({ $isOpen }) => ($isOpen ? tw`bg-blue-010` : tw`bg-transparent`)}
`;

const ItemTitleContainer = styled.div<{ $isOpen?: boolean; $canOpen?: boolean }>`
  ${tw`cursor-pointer`}
  ${tw`flex items-center justify-between gap-4`}
  ${tw`w-full py-3.5`}
  ${tw`text-sm`}
  ${tw`bg-transparent`}
  ${({ $isOpen }) => $isOpen && tw`font-semibold`}
  ${({ $canOpen }) => !$canOpen && tw`opacity-50 cursor-not-allowed`}
`;

const ItemContentWrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`text-xs hidden`}
  ${({ $isOpen }) => $isOpen && tw`block`}
`;

export const ItemTitle = ({ children, rightEl, ...props }: ItemTitleProps) => (
  <ItemTitleContainer {...props}>
    <div tw="flex-1 flex items-center gap-2 truncate">{children}</div>
    {rightEl}
  </ItemTitleContainer>
);

export const Item = ({ children, className, canOpen = true }: ItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, ...rest] = React.Children.toArray(children);

  if (!React.isValidElement(title) || title.type !== ItemTitle) {
    throw new Error('First child of SideBar.Item must be a SideBar.Item.Title');
  }

  return (
    <ItemContainer $isOpen={isOpen && canOpen} className={className}>
      {React.cloneElement(title as React.ReactElement, {
        rightEl: rest.length > 0 ? <NavArrowDown tw="w-5 h-5" /> : title.props.rightEl,
        onClick: rest.length > 0 ? () => setIsOpen(!isOpen) : title.props.onClick,
        $isOpen: isOpen && canOpen,
        $canOpen: canOpen,
      })}
      {rest.length > 0 && (
        <ItemContentWrapper $isOpen={isOpen && canOpen}>
          <div tw="pt-1 pb-4">{rest}</div>
        </ItemContentWrapper>
      )}
      <hr />
    </ItemContainer>
  );
};

Item.Title = ItemTitle;
SideBar.Item = Item;

export default SideBar;
