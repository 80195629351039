import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import 'twin.macro';

import { Modal, ModalProps, Button, InputText, ContextField as Field } from '@new-components';
import { useCreateAttendee } from '@hooks/useAttendees';
import { toastify } from '@helpers/toastify';

type FormValues = {
  name: string;
  email: string;
};

export type Props = ModalProps & {
  name: string;
  type: 'internal' | 'external';
};

const AddAttendeeModal = ({ isOpen, onClose, name, type }: Props) => {
  const { t } = useTranslation();

  const defaultValues: FormValues = {
    name,
    email: '',
  };

  const isInternal = type === 'internal';

  const methods = useForm<FormValues>({ defaultValues });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutateAsync: createAttendee, isLoading } = useCreateAttendee();

  const onSubmit = async (values: FormValues) => {
    try {
      await createAttendee({ ...values, internal: isInternal });
      toastify('success', t('attendees.create.success'));
      onClose();
    } catch (err: any) {
      const message = t('globals.error_occurred');

      // TODO: (back) review error format/handling
      if (err.response?.data) {
        const { errors } = err.response.data;

        if (errors && errors.email[0]) {
          toastify(
            'error',
            t(`contacts.errors.email.${errors.email[0].error}`, { defaultValue: message }),
          );
          return;
        }
      }

      toastify('error', message);
    }
  };

  const title = isInternal
    ? t('contacts.actions.add_internal_attendee')
    : t('contacts.actions.add_external_attendee');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header tw="mb-6">
            <h3>{title}</h3>
          </Modal.Header>
          <Modal.Body tw="mb-6">
            <div tw="mb-4">
              <label htmlFor="name" tw="block mb-1 text-label">
                {t('contacts.attributes.name')}
              </label>
              <Field name="name" required>
                <InputText type="text" placeholder={t('globals.enter_here')} />
              </Field>
            </div>
            <div>
              <label htmlFor="email" tw="block mb-1 text-label">
                {t('contacts.attributes.email')}
              </label>
              <Field name="email" pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}>
                <InputText placeholder={t('globals.enter_here')} />
              </Field>
            </div>
          </Modal.Body>
          <Modal.Footer tw="pb-4 flex gap-x-4">
            <Button shade="secondary" tw="w-full justify-center" onClick={onClose}>
              {t('globals.cancel')}
            </Button>
            <Button
              type="submit"
              tw="w-full justify-center"
              disabled={!isValid}
              isLoading={isLoading}>
              {t('globals.save')}
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddAttendeeModal;
