import React from 'react';
import tw, { styled } from 'twin.macro';
import FileMissingPreview from '../FileMissingPreview';
import FileLostPreview from '../FileLostPreview';
import FilePreview from '../FilePreview';

type Size = 'lg' | 'sm';

export type Props = React.ComponentPropsWithoutRef<'div'> & {
  src: string;
  status: 'attached' | 'missing' | 'lost' | 'not_required' | 'void';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onErrorClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: Size;
  nbPages?: number;
};

const StyledWrapper = styled.div<{ size?: Size }>`
  ${tw`w-[276px] h-[388px] min-h-[388px] overflow-hidden rounded-lg`}
  ${({ size }) => size === 'lg' && tw`w-[768px] h-[712px]`}
`;

const StyledVoid = tw.div`
  w-full h-full
  bg-blueGray-020
  border border-blueGray-100 rounded-lg
  animate-truepulse
`;

const comps = {
  missing: FileMissingPreview,
  lost: FileLostPreview,
  attached: FilePreview,
  not_required: () => null,
  void: StyledVoid,
};

const ReceiptPreview = ({
  src,
  status,
  size = 'sm',
  nbPages = 1,
  onErrorClick,
  ...props
}: Props) => {
  const Component = comps[status];

  return (
    <StyledWrapper size={size} {...props}>
      <Component
        href={src}
        src={src}
        onErrorClick={onErrorClick}
        type={nbPages > 1 ? 'pdf' : 'img'}
      />
    </StyledWrapper>
  );
};

export default ReceiptPreview;
