import React, { useMemo } from 'react';
import tw from 'twin.macro';
import RSelect, {
  Props as SelectProps,
  ControlProps,
  OptionProps,
  MultiValueProps,
  components,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MenuProps,
  GroupBase,
} from 'react-select';
import Creatable from 'react-select/creatable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withAsyncPaginate, AsyncPaginateProps as AsyncProps } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import { Xmark, NavArrowDown } from 'iconoir-react';

import InputWrapper, { Props as InputWrapperProps } from '../InputWrapper';
import Checkbox from '../Checkbox';
import Chip from '../Chip';
import SubMenu from '../SubMenu';
import ResponsiveWrapper from '../ResponsiveWrapper';

type Additional = {
  page: number;
};

export type Option = { value: any; label: any; isSelected?: boolean };

type DefaultProps = SelectProps &
  Omit<InputWrapperProps, 'ref' | 'children' | 'defaultValue'> & {
    disabled?: boolean;
    labelName?: string;
    onCreateOption?: (input: string) => void;
  };

export type Props =
  | (DefaultProps & {
      async?: false;
      loadOptions?: never;
    })
  | (DefaultProps &
      AsyncProps<unknown, GroupBase<Option>, Additional, boolean> & {
        async: true;
      });

const sizes = {
  sm: tw`pr-2`,
  md: tw`pr-3`,
  lg: tw`pr-3.5`,
};

const multiSizes = {
  sm: tw`py-1`,
  md: tw`py-2 pl-4`,
  lg: tw`py-4`,
};

const Control = ({ children, ...props }: ControlProps) => {
  const { isFocused, isDisabled, isMulti, hasValue, selectProps } = props;
  const { size = 'md', hasError, error, icon, isOCR = false } = selectProps as Partial<Props>;

  return (
    <components.Control {...props}>
      <InputWrapper
        icon={icon}
        size={size}
        hasError={hasError}
        error={error}
        isFocused={isFocused}
        isDisabled={isDisabled}
        isOCR={isOCR}
        css={[sizes[size], isMulti && hasValue && multiSizes[size]]}>
        {children as React.ReactElement}
      </InputWrapper>
    </components.Control>
  );
};

const Menu = ({ children, ...props }: MenuProps) => (
  <components.Menu {...props}>
    <SubMenu>{children}</SubMenu>
  </components.Menu>
);

const Group = ({ children, ...props }: any) => (
  <>
    <components.Group {...props}>{children}</components.Group>
    <hr tw="mx-4 my-2 last:hidden" />
  </>
);

const GroupHeading = ({ data, ...props }: any) => {
  return (
    <components.GroupHeading {...props} data={data}>
      {data.label !== '-' && (
        <div tw="py-1 px-4">
          <span tw="text-label">{data.label}</span>
        </div>
      )}
    </components.GroupHeading>
  );
};

const OptionItem = ({ data, isSelected, isFocused, isMulti, ...props }: OptionProps) => {
  const { label, isDisabled } = data as { label: string; isDisabled?: boolean };

  return (
    <components.Option
      {...props}
      isSelected={isSelected}
      isMulti={isMulti}
      isFocused={!isDisabled && isFocused}
      data={data}>
      <SubMenu.Item
        type="button"
        disabled={isDisabled}
        css={[
          tw`font-semibold gap-2`,
          isMulti && !isSelected && tw`font-normal`,
          isFocused && tw`bg-blue-005`,
        ]}>
        {isMulti && (
          <Checkbox
            as="span"
            checked={isSelected}
            readOnly
            aria-label={label}
            disabled={isDisabled}
          />
        )}
        <span tw="truncate">{label}</span>
      </SubMenu.Item>
    </components.Option>
  );
};

const MultiValue = ({ removeProps, ...props }: MultiValueProps) => {
  const onClickRemove = props.isDisabled
    ? undefined
    : (e: React.MouseEvent<HTMLButtonElement>) =>
        removeProps?.onClick?.(e as unknown as React.MouseEvent<HTMLDivElement>);

  return (
    <components.MultiValue
      {...props}
      removeProps={removeProps}
      components={{ ...props.components, Remove: () => null }}>
      <Chip onClickRemove={onClickRemove} disabled={props.isDisabled}>
        {props.children}
      </Chip>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  const { isMulti, hasValue } = props;
  const [last, ...rest] = React.Children.toArray(children).reverse();

  return (
    <div tw="w-0 flex-1 [>div>input]:w-0">
      <components.ValueContainer {...props}>
        <div
          tw="max-w-[90%] mr-1"
          style={{ display: isMulti && hasValue ? 'flex' : 'inline-grid', gridArea: '1/1/2/3' }}>
          <ResponsiveWrapper overflow="hidden" css={[isMulti && hasValue && tw`[> div]:h-6`]}>
            {rest.reverse()}
          </ResponsiveWrapper>
        </div>
        {last}
      </components.ValueContainer>
    </div>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <Xmark tw="w-4.5 h-4.5 text-blueGray-700 cursor-pointer" />
  </components.ClearIndicator>
);

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <NavArrowDown tw="w-4.5 h-4.5 text-blueGray-700 cursor-pointer" />
  </components.DropdownIndicator>
);

const Select = React.forwardRef(({ disabled, labelName, async = false, ...props }: Props, ref) => {
  const { t } = useTranslation();

  const labeled = useMemo(() => {
    if (labelName && props.options) {
      return props.options.map((option: any) => ({ ...option, label: option[labelName] }));
    }

    return props.options;
  }, [props.options, labelName]);

  const sorted = useMemo(() => {
    if (
      props.isMulti &&
      labeled &&
      labeled.length &&
      (labeled[0] as Option).isSelected !== undefined
    ) {
      const selected = labeled.filter(o => (o as Option).isSelected);
      const unselected = labeled.filter(o => !(o as Option).isSelected);

      return [
        { label: 'Selected', options: selected },
        { label: '-', options: unselected },
      ];
    }

    return labeled;
  }, [props.isMulti, labeled]);

  const CSelect = props.onCreateOption ? Creatable : RSelect;
  const Component = async ? (withAsyncPaginate(CSelect) as React.ElementType) : CSelect;

  return (
    <Component
      ref={ref}
      isDisabled={disabled}
      hideSelectedOptions={false}
      closeMenuOnSelect={!props.isMulti}
      placeholder={t('globals.select')}
      {...props}
      isSearchable={!!props.onCreateOption || props.isSearchable}
      options={sorted}
      styles={{
        control: (baseStyles: any) => ({ ...baseStyles, ...tw`min-h-0` }),
        menu: (baseStyles: any) => ({ ...baseStyles, ...tw`w-max min-w-full` }),
        multiValue: (baseStyles: any) => ({
          ...baseStyles,
          ...tw`inline-block max-w-full min-w-[65px]`,
        }),
        singleValue: (baseStyles: any) => ({ ...baseStyles, ...tw`truncate` }),
        placeholder: (baseStyles: any) => ({ ...baseStyles, ...tw`text-blueGray-700` }),
        valueContainer: (baseStyles: any) => ({ ...baseStyles, ...tw`flex-nowrap` }),
        indicatorsContainer: (baseStyles: any) => ({ ...baseStyles, ...tw`order-last` }),
        ...props.styles,
      }}
      unstyled
      components={{
        Control,
        Menu,
        Group,
        GroupHeading,
        Option: OptionItem,
        MultiValue,
        ValueContainer,
        ClearIndicator,
        DropdownIndicator,
        ...props.components,
      }}
    />
  );
});

Select.displayName = 'Select';

export default Select;
