import React, { useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Xmark, NavArrowLeft } from 'iconoir-react';

import Button from '../Button';

export type Props = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

export type LeftPanelProps = React.ComponentPropsWithoutRef<'div'>;
export type RightPanelProps = React.ComponentPropsWithoutRef<'div'> & { onClose?: () => void };

type ContextType = {
  isLeftPanelOpen: boolean;
  setIsLeftPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DrawerContext = React.createContext<ContextType>({
  isLeftPanelOpen: false,
  setIsLeftPanelOpen: () => null,
});

export const useDrawer = () => React.useContext(DrawerContext);

export const StyledOverlay = styled.div<{ $isOpen: boolean }>`
  ${tw`fixed inset-0 -z-10 bg-black/50`}
  ${tw`opacity-0 transition-opacity ease-in-out duration-300`}
  ${({ $isOpen }) => $isOpen && tw`opacity-50 z-20`}
`;

const StyledDrawer = styled.div<{ $isOpen: boolean; $hasLeftPanel: boolean }>`
  ${tw`fixed z-30 pt-4 inset-0 sm:(p-4 left-auto)`}
  ${tw`transition-transform ease-in-out duration-300`}
  ${({ $isOpen }) => !$isOpen && tw`!right-0 translate-x-full`}
  ${({ $hasLeftPanel }) => $hasLeftPanel && tw`sm:(!left-0)`}
  ${tw`flex flex-row`}
`;

const StyledRightPanel = styled.div`
  ${tw`relative`}
  ${tw`flex-1 max-w-[600px] rounded-t-2xl`}
  ${tw`bg-white p-6`}
  ${tw`sm:(w-[600px] rounded-lg not-first:rounded-l-none)`}
`;

const StyledLeftPanel = styled.div<{ $isOpen?: boolean }>`
  ${tw`absolute z-50 p-4 inset-0`}
  ${tw`flex-1 bg-dark-2`}
  ${tw`hidden sm:(!flex relative p-6)`}
  ${({ $isOpen }) => $isOpen && tw`block`}
`;

const Footer = styled.div`
  ${tw`sticky inset-x-0 bottom-0`}
  ${tw`pt-4 bg-white`}
`;

const Drawer = ({ children, isOpen, onClose }: Props) => {
  const [right, left] = React.Children.toArray(children).reverse();

  return (
    <>
      <StyledOverlay $isOpen={isOpen} onClick={onClose} />
      <StyledDrawer $isOpen={isOpen} $hasLeftPanel={!!left}>
        {left}
        {React.isValidElement(right) && <right.type {...right.props} onClose={onClose} />}
      </StyledDrawer>
    </>
  );
};

const RightPanel = ({ onClose, ...props }: RightPanelProps) => (
  <StyledRightPanel>
    <div tw="absolute right-6 top-6 z-10">
      <Button onClick={onClose} shade="tertiary" rightIcon={<Xmark />} size="sm" />
    </div>
    {props.children}
  </StyledRightPanel>
);

const LeftPanel = ({ children }: LeftPanelProps) => {
  const { isLeftPanelOpen, setIsLeftPanelOpen } = useDrawer();

  return (
    <StyledLeftPanel $isOpen={isLeftPanelOpen}>
      <div tw="sm:hidden">
        <Button
          onClick={() => setIsLeftPanelOpen(false)}
          shade="link"
          rightIcon={<NavArrowLeft />}
          size="sm"
          tw="text-white"
        />
      </div>
      <div tw="h-full overflow-scroll">{children}</div>
    </StyledLeftPanel>
  );
};

export const DrawerProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);

  const value = useMemo(
    () => ({ isLeftPanelOpen, setIsLeftPanelOpen }),
    [isLeftPanelOpen, setIsLeftPanelOpen],
  );

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

Drawer.LeftPanel = LeftPanel;
Drawer.RightPanel = RightPanel;
Drawer.Footer = Footer;

export default Drawer;
