import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Send, Xmark } from 'iconoir-react';
import 'twin.macro';

import { usePostCorrectionRequest } from '@hooks/useExpenses';
import { Button, Checkbox, Drawer, Form, ContextField as Field, TextArea } from '@new-components';

import { toastify } from '@helpers/toastify';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  expenseId: string | null;
};

type FormValues = {
  message: string;
  notification: boolean;
};

const CorrectionRequestDrawer = ({ isOpen, onClose, expenseId }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: postCorrectionRequest, isLoading } = usePostCorrectionRequest(
    expenseId || '',
  );

  const onSubmit = async (values: FormValues) => {
    if (!expenseId) return;

    try {
      await postCorrectionRequest(values);
      toastify('success', t('correction_request.create.success'));
      onClose();
    } catch (err) {
      toastify('error', t('globals.error_occurred'));
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div tw="p-6 bg-white rounded-t-2xl sm:rounded-lg max-w-[600px] flex flex-col justify-between">
        <Form defaultValues={{}} onSubmit={onSubmit}>
          <div>
            <div tw="flex justify-between items-center my-4">
              <h3>{t('expenses.correction_request.drawer.title')}</h3>
              <Button onClick={onClose} shade="tertiary" rightIcon={<Xmark />} size="sm" />
            </div>
            <div>
              <span tw="block break-words">
                <Trans
                  i18nKey="expenses.correction_request.drawer.text"
                  components={{
                    b: <strong tw="font-bold" />,
                    n: <br />,
                  }}
                />
              </span>
            </div>
            <div tw="bg-blueGray-020 rounded-lg p-6 my-4">
              <h5 tw="mb-4">{t('expenses.correction_request.drawer.request_description')}</h5>
              <Field name="message">
                <TextArea
                  placeholder={t('expenses.correction_request.drawer.enter_here')}
                  tw="resize-none"
                />
              </Field>
            </div>
            <Field
              name="notification"
              render={({ field }) => (
                <label tw="text-sm">
                  <Checkbox
                    name="notification"
                    value="1"
                    checked={!!field.value}
                    onChange={field.onChange}
                  />
                  <span tw="ml-2 align-middle">
                    {t('expenses.correction_request.drawer.inform_employee')}
                  </span>
                </label>
              )}
            />
          </div>
          <div tw="flex gap-4 py-4 justify-between">
            <Button size="md" shade="secondary" tw="flex-1" onClick={onClose}>
              {t('globals.cancel')}
            </Button>
            <Button
              type="submit"
              size="md"
              shade="primary"
              leftIcon={<Send />}
              isLoading={isLoading}
              tw="flex-1">
              {t('expenses.correction_request.drawer.send_request')}
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default CorrectionRequestDrawer;
