import { apiClient } from '../constants';

export type Attendee = {
  name: string;
  email: string;
};

export type CreateAttendeeParams = {
  name: string;
  email: string;
  internal: boolean;
};

export const createAttendee = (params: CreateAttendeeParams) =>
  apiClient.post<Attendee>('/contacts.json', params);
