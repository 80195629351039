import React from 'react';
import tw, { styled } from 'twin.macro';
import { NavArrowRight } from 'iconoir-react';
import Button from '../Button';

export type Props = React.PropsWithChildren<{
  isOpen?: boolean;
}>;

type ToggleButtonProps = {
  isOpen: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const ToggleButton = ({ isOpen, onClick }: ToggleButtonProps) => (
  <Button
    shade="ghost"
    rightIcon={<NavArrowRight />}
    onClick={onClick}
    css={isOpen && tw`text-blue-100 bg-blue-005 [>svg]:rotate-90`}
  />
);

export const Header = tw.div``;

export const Content = tw.div``;

const ContentWrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`hidden pl-4 pr-10`}
  ${({ $isOpen }) => $isOpen && tw`block`}
`;

const AccordionWrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`border-b border-solid border-blueGray-100 first:border-t last:border-b-0`}
  ${tw`border-l-2 border-l-white hover:border-l-blue-005`}
  ${({ $isOpen }) =>
    $isOpen && tw`border-l-primary-2 hover:border-l-primary-2 border-b-turquoise-100`}
`;

const Accordion = ({ isOpen = false, children }: Props) => {
  const [header, ...rest] = React.Children.toArray(children);

  if (!React.isValidElement(header) || header.type !== Header) {
    throw new Error('First child of Accordion must be an Accordion.Header');
  }

  return (
    <AccordionWrapper $isOpen={isOpen}>
      {header}
      <ContentWrapper $isOpen={isOpen}>{rest}</ContentWrapper>
    </AccordionWrapper>
  );
};

Accordion.Header = Header;
Accordion.Content = Content;
Accordion.ToggleButton = ToggleButton;

export default Accordion;
