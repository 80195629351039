import React from 'react';
import tw, { styled } from 'twin.macro';

export type Size = 'md' | 'sm';

export type Props = React.PropsWithChildren<{
  size?: Size;
  inline?: boolean;
}>;

export type ItemProps = React.ComponentProps<'input'> & {
  color?: string;
  toSee?: boolean;
};

const sizes = {
  md: '',
  sm: tw`[>svg]:(w-4 h-4) px-2.5`,
};

const StyledItem = styled.div<{ $color?: string }>`
  ${tw`relative`}
  ${tw`flex items-center justify-center gap-1.5 truncate`}
  ${tw`py-2.5 rounded-lg`}
  ${tw`cursor-pointer shadow-none`}
  ${tw`text-xs uppercase font-bold `}
  ${tw`text-blueGray-700 bg-blue-005 hover:bg-blue-010`}
  ${tw`transition-all ease-in-out`}
  ${tw`[>svg]:(w-5 h-5) px-4`}

  input:disabled + & {
    ${tw`hover:bg-blue-005`}
    ${tw`cursor-default`}
  }

  input:checked + & {
    ${tw`text-blue-700 bg-white shadow hover:bg-white`}
    ${({ $color }) => $color && `color: ${$color};`};
    ${tw`[> svg]:text-blue-100`}
  }
`;

const StyledSwitch = styled.div<{ $size: Size }>`
  ${tw`inline-flex rounded-lg gap-0.5 p-0.5 bg-blue-005`}

  ${StyledItem} {
    ${({ $size }) => $size && sizes[$size]}
  }

  & > label {
    ${tw`flex-1`}
  }
`;

const Dot = styled.span`
  ${tw`absolute w-1.5 h-1.5 rounded-full bg-red-500 top-1.5 right-1.5`}
`;

const Item = ({ children, color, toSee = false, ...props }: ItemProps) => (
  <label>
    <input {...props} type="radio" tw="sr-only" />
    <StyledItem $color={color}>
      {children} {toSee && <Dot />}
    </StyledItem>
  </label>
);

const Switch = ({ children, size = 'md', ...props }: Props) => (
  <StyledSwitch {...props} $size={size}>
    {children}
  </StyledSwitch>
);

Switch.Item = Item;

export default Switch;
