import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Hit } from 'instantsearch.js';

import { nextSteps } from '../constants';

type NextSteps = (typeof nextSteps)[keyof typeof nextSteps];
type ContextType = {
  is: (step: NextSteps, hit: Hit) => boolean;
  set: (step: NextSteps, hit: Hit) => void;
};

const ActionHitsContext = createContext({} as ContextType);

export const useActionHits = () => useContext(ActionHitsContext);

export const ActionHitsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [ids, setIds] = useState<Record<NextSteps, string[]>>({
    controlled: [],
    approved: [],
  });

  const is = useCallback((step: NextSteps, hit: Hit) => ids[step].includes(hit.objectID), [ids]);

  const set = useCallback(
    (step: NextSteps, hit: Hit) =>
      setIds({
        ...ids,
        [step]: [...ids[step], hit.objectID],
      }),
    [ids],
  );

  const value = useMemo(() => ({ is, set }), [is, set]);

  return <ActionHitsContext.Provider value={value}>{children}</ActionHitsContext.Provider>;
};

export default { ActionHitsProvider, useActionHits };
