import React from 'react';

import { paths } from './constants';

import ExpensesLayout from '../screens/Expenses';
import AllExpenses from '../screens/Expenses/All';
import MyExpenses from '../screens/Expenses/Mine';
import CardExpenses from '../screens/Expenses/Card';
import ExternalExpenses from '../screens/Expenses/External';
import EmployeeExpenses from '../screens/Expenses/Employee';
import KmExpenses from '../screens/Expenses/Km';
import SupplierExpenses from '../screens/Expenses/Supplier';
import ExpensesToControl from '../screens/Expenses/ToControl';

// NOTE: ToApprove is the same as ToControl
const ExpensesToApprove = ExpensesToControl;

const routes = [
  {
    path: paths.EXPENSES,
    element: <ExpensesLayout />,
    children: [
      { index: true, element: <AllExpenses /> },
      { path: paths.ALL_EXPENSES, element: <AllExpenses /> },
      { path: paths.CARD_EXPENSES, element: <CardExpenses /> },
      { path: paths.MINE_EXPENSES, element: <MyExpenses /> },
      { path: paths.EXTERNAL_EXPENSES, element: <ExternalExpenses /> },
      { path: paths.EMPLOYEE_EXPENSES, element: <EmployeeExpenses /> },
      { path: paths.KM_EXPENSES, element: <KmExpenses /> },
      { path: paths.SUPPLIER_EXPENSES, element: <SupplierExpenses /> },
      { path: paths.TO_CONTROL_EXPENSES, element: <ExpensesToControl /> },
      { path: paths.TO_APPROVE_EXPENSES, element: <ExpensesToApprove /> },
    ],
  },
];

export default routes;
