import { WarningTriangle } from 'iconoir-react';
import React from 'react';
import tw, { styled } from 'twin.macro';

type Size = 'xs' | 'sm' | 'md' | 'lg';

type Color =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'ghost'
  | 'link'
  | 'white'
  | 'invert';

type StyledProps = {
  $size: Size;
  $shade: Color;
  $isIconBtn: boolean;
  $hasLeftIcon: boolean;
  $hasRightIcon: boolean;
};

export type Props = React.ComponentProps<'button'> & {
  type?: 'button' | 'submit' | 'reset';
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  shade?: Color;
  size?: Size;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const sizes = {
  xs: tw`py-1 px-2.5 gap-1 text-xs [>svg]:(w-3.5 min-w-3.5 h-3.5)`,
  sm: tw`py-2 px-3.5 gap-1 text-xs [>svg]:(w-4 min-w-4 h-4)`,
  md: tw`py-2.5 px-4 gap-2 text-sm [>svg]:(w-4 min-w-4 h-4)`,
  lg: tw`py-4 px-4.5 gap-2 text-base [>svg]:(w-5 min-w-5 h-5)`,
};

const iconBtnSizes = {
  xs: tw`p-0 rounded [>svg]:(w-4 h-4)`,
  sm: tw`p-2 [>svg]:(w-4 h-4)`,
  md: tw`p-2 [>svg]:(w-6 h-6)`,
  lg: tw`p-4 [>svg]:(w-6 h-6)`,
};

const hasLeftIconSizes = {
  xs: tw`pl-2`,
  sm: tw`pl-2.5`,
  md: tw`pl-3`,
  lg: tw`pl-3.5`,
};

const hasRightIconSizes = {
  xs: tw`pr-2`,
  sm: tw`pr-2.5`,
  md: tw`pr-3`,
  lg: tw`pr-3.5`,
};

const shades = {
  primary: tw`
      text-white bg-blue-500
      hover:bg-blue-700
      focus:(text-white bg-blue-700)
      disabled:(text-blueGray-300 bg-blueGray-050)`,
  secondary: tw`
      text-blue-500 bg-blue-005
      hover:bg-blue-010
      focus:bg-blue-040
      disabled:(text-blueGray-300 bg-blueGray-050)`,
  tertiary: tw`
      text-blue-700 bg-white border-blueGray-100
      hover:(bg-blue-005 text-blue-100 border-blue-100)
      focus:(bg-blue-010 text-blue-100 border-blue-100)
      [&.active]:(bg-blue-010 text-blue-100 border-blue-100)
      disabled:(text-blueGray-300 border-blueGray-300 bg-white)`,
  warning: tw`
      text-orange-700 bg-white border-blueGray-100
      hover:(bg-orange-050 text-orange-700 border-orange-700)
      focus:(bg-orange-050 text-orange-700 border-orange-700)
      disabled:(text-blueGray-300 border-blueGray-300 bg-white)`,
  ghost: tw`
      text-blue-700 bg-transparent
      hover:(bg-blue-005 text-blue-100)
      focus:(bg-blue-005 text-blue-100)
      disabled:(text-blueGray-300 bg-white)`,
  link: tw`
      p-0 bg-transparent
      hover:(bg-transparent underline text-inherit)
      focus:(bg-transparent)
      disabled:(text-blueGray-300)`,
  white: tw`
      text-primary bg-white
      hover:(bg-blueGray-050)
      focus:(bg-blueGray-080)
      disabled:(text-white/25 bg-white/10)`,
  invert: tw`
      text-white bg-white/20
      hover:bg-white/25
      focus:(bg-white/30)
      disabled:(text-white/30 bg-white/10)`,
};

export const StyledButton = styled.button<StyledProps>`
  ${tw`
    rounded-lg
    border-solid
    border
    border-transparent
    flex
    items-center
    justify-center
    font-semibold
    text-left
    [>svg]:stroke-2
  `}

  ${({ $size }) => sizes[$size]}
  ${({ $shade }) => shades[$shade]}
  ${({ $hasLeftIcon, $size }) => $hasLeftIcon && hasLeftIconSizes[$size]}
  ${({ $hasRightIcon, $size }) => $hasRightIcon && hasRightIconSizes[$size]}
  ${({ $isIconBtn, $size }) => $isIconBtn && iconBtnSizes[$size]}
  ${({ $shade, disabled, $isIconBtn }) =>
    $isIconBtn &&
    ($shade === 'tertiary' || $shade === 'ghost') &&
    !disabled &&
    tw`text-blue-500 hover:text-blue-100 focus:text-blue-100`}
`;

const Spinner = () => (
  <svg
    tw="animate-spin h-5 w-5 text-blueGray-300"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24">
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

const Button = ({
  children,
  shade = 'primary',
  size = 'md',
  leftIcon,
  rightIcon,
  type = 'button',
  disabled = false,
  isLoading = false,
  onClick,
  ...props
}: Props) => {
  const isIconBtn = !children;

  let icon = leftIcon;
  if (isLoading) icon = <Spinner />;
  else if (shade === 'warning') icon = <WarningTriangle />;

  return (
    <StyledButton
      {...props}
      onClick={e => {
        (e.target as HTMLElement).blur();
        onClick?.(e);
      }}
      type={type}
      disabled={disabled || isLoading}
      $isIconBtn={isIconBtn}
      $size={size}
      $shade={shade}
      $hasLeftIcon={!!icon}
      $hasRightIcon={!!rightIcon}>
      {icon}
      {children}
      {shade !== 'warning' && rightIcon}
    </StyledButton>
  );
};

Button.Spinner = Spinner;

export default Button;
