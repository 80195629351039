import React from 'react';

import { VirtualRefinementList } from '@new-components/InstantSearch';

import Accountability from './Accountability';
import Amount from './Amount';
import AnalyticCode from './AnalyticCode';
import Billable from './Billable';
import BusinessCode from './BusinessCode';
import Category from './Category';
import Complete from './Complete';
import Date from './Date';
import DepartmentName from './DepartmentName';
import DriverCode from './DriverCode';
import LicensePlate from './LicensePlate';
import Location from './Location';
import MerchantName from './MerchantName';
import PaymentStatus from './PaymentStatus';
import Receipt from './Receipt';
import ReimbursementStatus from './ReimbursementStatus';
import Refund from './Refund';
import Settled from './Settled';
import SourceType from './SourceType';
import SupplierCode from './SupplierCode';
import Step from './Step';
import SupplierCountry from './SupplierCountry';
import ToComplete from './ToComplete';
import UserName from './UserName';
import VehicleName from './VehicleName';
import DueAt from './DueAt';

export type Props = React.ComponentProps<'div'> & {
  indexId: string;
  displayAccountability: boolean;
  displayBillable: boolean;
  displayBusinessCodes: boolean;
  displayDriverCode: boolean;
  displayTags: boolean;
  displayLicensePlate: boolean;
};

const Filters = ({
  indexId,
  displayAccountability = true,
  displayBillable = true,
  displayBusinessCodes = true,
  displayDriverCode = true,
  displayTags = true,
  displayLicensePlate = true,
  ...props
}: Props) => (
  <div {...props}>
    <VirtualRefinementList attribute="type" />
    <VirtualRefinementList attribute="card_token" />
    <VirtualRefinementList attribute="reimbursement_id" />
    {indexId === 'mine' && <ToComplete />}
    {['all', 'mine', 'to_control', 'to_approve'].includes(indexId) && <SourceType />}
    <Date i18nKey={indexId === 'supplier' ? 'invoice_date' : 'date'} />
    {(indexId === 'supplier' || ['to_control', 'to_approve'].includes(indexId)) && <DueAt />}
    <Amount />
    {indexId !== 'mine' && <UserName />}
    {indexId !== 'km' && <Category />}
    {indexId !== 'supplier' && <Receipt />}
    <Step />
    {indexId === 'supplier' && <PaymentStatus />}
    {!['to_control', 'to_approve'].includes(indexId) && <Complete />}
    {indexId !== 'km' && <DepartmentName />}
    {displayAccountability && <Accountability />}
    {displayBillable && <Billable />}
    {displayBusinessCodes && <BusinessCode />}
    {displayTags && <AnalyticCode />}
    {['all', 'card', 'mine', 'supplier', 'to_control', 'to_approve'].includes(indexId) && (
      <MerchantName />
    )}
    {['all', 'card', 'mine', 'to_control', 'to_approve'].includes(indexId) && <Location />}
    {indexId === 'supplier' && <SupplierCountry />}
    {['card', 'supplier', 'to_control', 'to_approve'].includes(indexId) && <SupplierCode />}
    {['all', 'card', 'mine'].includes(indexId) && <Settled />}
    {['all', 'card', 'mine', 'to_control', 'to_approve'].includes(indexId) && <Refund />}
    {(displayLicensePlate || indexId === 'km') && <VehicleName />}
    {displayDriverCode && ['all', 'card', 'mine', 'to_control', 'to_approve'].includes(indexId) && (
      <DriverCode />
    )}
    {(displayLicensePlate || indexId === 'km') && <LicensePlate />}
    {['all', 'employee', 'mine', 'km'].includes(indexId) && <ReimbursementStatus />}
  </div>
);

export default Filters;
