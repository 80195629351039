import styled from 'styled-components';
import { HexColor } from '../../themes/styled.d';

export const MainContainer = styled.div`
  align-items: stretch;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  right: 1rem;
`;

export const ThemeButton = styled.button<{ $color: HexColor; $selected: boolean }>`
  background: ${props => (props.$selected ? props.$color : 'transparent')};
  border: 1px solid ${props => props.$color};
  border-radius: 1rem;
  color: ${props => (props.$selected ? '#fff' : props.$color)};
  cursor: pointer;
  padding: 0.5rem 1rem;

  &:hover {
    background: ${props => props.$color};
    color: #fff;
  }
`;
