import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { ContextField as Field, InputText, Select, Label } from '@new-components';
import { FieldStates } from '../../constants';
import { loadOptions, fieldProps } from './utils';

type FieldProps = {
  required?: boolean;
  disabled?: boolean;
};

type Props = React.ComponentPropsWithoutRef<'fieldset'> &
  FieldProps & {
    expenseId: string;
    fields: Record<string, FieldStates>;
  };

const VehicleField = ({ expenseId, ...props }: FieldProps & { expenseId: string }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label hasRequired={props.required} tw="mb-1">
        {t('expenses.list.filters.vehicle_name')}
      </Label>
      <Field name="vehicle" label={t('expenses.list.filters.vehicle_name')} {...props}>
        <Select loadOptions={loadOptions(expenseId, 'vehicle')} />
      </Field>
    </div>
  );
};

const FuelVolumeField = ({ required, ...props }: FieldProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Field
        name="vehicleFuelVolume"
        label={t('expenses.list.form.vehicle_fuel_volume.label')}
        valueAsNumber
        required={required}
        {...props}>
        <InputText
          type="number"
          size="md"
          rightEl={
            <strong tw="whitespace-nowrap" css={[required && tw`has-required`]}>
              L
            </strong>
          }
        />
      </Field>
    </div>
  );
};

const KmField = ({ required, ...props }: FieldProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Field
        name="vehicleKilometers"
        label={t('expenses.list.form.vehicle_kilometers.label')}
        valueAsNumber
        required={required}
        {...props}>
        <InputText
          type="number"
          size="md"
          rightEl={
            <strong tw="whitespace-nowrap" css={[required && tw`has-required`]}>
              Km
            </strong>
          }
        />
      </Field>
    </div>
  );
};

const KmFieldset = ({ expenseId, fields, ...props }: Props) => {
  if (!fields.vehicle && !fields.vehicle_fuel_volume && !fields.vehicle_kilometers) return null;

  return (
    <fieldset tw="flex flex-col gap-y-4" className="group" {...props}>
      {fields.vehicle && <VehicleField expenseId={expenseId} {...fieldProps(fields.vehicle)} />}
      {fields.vehicle_fuel_volume && (
        <FuelVolumeField {...fieldProps(fields.vehicle_fuel_volume)} />
      )}
      {fields.vehicle_kilometers && <KmField {...fieldProps(fields.vehicle_kilometers)} />}
    </fieldset>
  );
};

export default KmFieldset;
