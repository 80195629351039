/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import 'twin.macro';

import { Skeleton, Tr } from '@new-components';

const SkeletonHit = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expenses.list.table' });

  return (
    <Tr>
      <MediaQuery minWidth={768}>
        <td tw="w-12 p-1.5" aria-label="Select">
          <Skeleton tw="w-4 h-4 ml-1" />
        </td>
      </MediaQuery>
      <td tw="w-28" aria-label={t('at')}>
        <Skeleton />
      </td>
      <td tw="w-36" aria-label={t('type')}>
        <Skeleton tw="h-5" />
      </td>
      <td tw="w-52" aria-label={t('title')}>
        <Skeleton />
      </td>
      <td tw="w-36" aria-label={t('expense_category')}>
        <Skeleton />
      </td>
      <td tw="w-24" aria-label={t('license_plate')}>
        <Skeleton />
      </td>
      <td tw="w-24" aria-label={t('receipt')}>
        <Skeleton tw="h-5" />
      </td>
      <td tw="w-24" aria-label={t('step')}>
        <Skeleton tw="h-5" />
      </td>
      <td tw="w-24" aria-label={t('amount')}>
        <Skeleton />
      </td>
    </Tr>
  );
};

export default SkeletonHit;
