export const paths = {
  ACCOUNTS: '/accounts',
  ANALYTICAL_AXES: '/analytical_axes',
  NEW_ANALYTICAL_AXE: '/analytical_axes/new',
  EDIT_ANALYTICAL_AXE: '/analytical_axes/:id/edit',
  ANALYTIC_CODES: '/analytic_codes',
  NEW_ANALYTIC_CODE: '/analytic_codes/new',
  EDIT_ANALYTIC_CODE: '/analytic_codes/:id/edit',
  BUSINESS_CODES: '/business_codes',
  NEW_BUSINESS_CODE: '/business_codes/new',
  EDIT_BUSINESS_CODE: '/business_codes/:id/edit',
  CARD_ORDERS: '/card_orders',
  CARD_PATTERNS: '/card_patterns',
  COMPANIES: '/companies',
  COMPANY_BANK_INFORMATION: '/company_bank_information',
  CONTACTS: '/contacts',
  NEW_CONTACT: '/contacts/new',
  EDIT_CONTACT: '/contacts/:id/edit',
  DASHBOARD: '/dashboard',
  DEPARTMENTS: '/departments',
  DRIVER_CODES: '/driver_codes',
  NEW_DRIVER_CODE: '/driver_codes/new',
  EDIT_DRIVER_CODE: '/driver_codes/:id/edit',
  EMPLOYEES: '/employees',
  EXPENSES: '/expenses',
  ALL_EXPENSES: '/expenses/all',
  CARD_EXPENSES: '/expenses/card',
  MINE_EXPENSES: '/expenses/mine',
  EXTERNAL_EXPENSES: '/expenses/external',
  EMPLOYEE_EXPENSES: '/expenses/employee',
  KM_EXPENSES: '/expenses/km',
  SUPPLIER_EXPENSES: '/expenses/supplier',
  TO_CONTROL_EXPENSES: '/expenses/to_control',
  TO_APPROVE_EXPENSES: '/expenses/to_approve',
  EXPENSE_CATEGORIES: '/expense_categories',
  NEW_EXPENSE_CATEGORY: '/expense_categories/new',
  IMPORT_EXPENSE_CATEGORIES: '/expense_categories/import',
  EDIT_EXPENSE_CATEGORY: '/expense_categories/:id/edit',
  EXPENSE_EXPORT_TEMPLATES: '/expense_export_templates',
  EXPENSE_EXPORT_TEMPLATE: '/expense_export_templates/:id',
  EDIT_EXPENSE_EXPORT_TEMPLATE: '/expense_export_templates/:id/edit',
  NEW_EXPENSE_EXPORT_TEMPLATE: '/expense_export_templates/new',
  PAYMENT_ERRORS: '/payment_errors',
  SUPPLIERS: '/suppliers',
  SUPPLIER: '/suppliers/:id',
  NEW_SUPPLIER: '/suppliers/new',
  EDIT_SUPPLIER: '/suppliers/:id/edit',
  TEMPORARY_CARD_DEACTIVATION_RULES: '/temporary_card_deactivation_rules',
  NEW_TEMPORARY_CARD_DEACTIVATION_RULE: '/temporary_card_deactivation_rules/new',
  EDIT_TEMPORARY_CARD_DEACTIVATION_RULE: '/temporary_card_deactivation_rules/:id/edit',
  VAT_RATES: '/vat_rates',
};
