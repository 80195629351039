import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavArrowDown, Plus, CreditCard, Car, CardWallet, PageFlip } from 'iconoir-react';

import { usePermissions } from '@hooks';
import { Dropdown, DropdownProps } from '@new-components';

type Props = Omit<DropdownProps, 'button'>;

const DropdownAdd = (props: Props) => {
  const abilities = [
    'create_external_expense',
    'create_km_expense',
    'create_employee_expense',
    'create_supplier_expense',
  ];

  const { t } = useTranslation();
  const { can } = usePermissions(abilities);

  if (!abilities.some(ability => can(ability))) return null;

  return (
    <Dropdown
      alignement="right"
      {...props}
      button={
        <Dropdown.Button shade="primary" size="md" leftIcon={<Plus />} rightIcon={<NavArrowDown />}>
          {t('globals.add')}
        </Dropdown.Button>
      }>
      <Dropdown.SubMenu>
        {can('create_employee_expense') && (
          <Dropdown.SubMenu.Item
            onClick={() => {
              window.location.href = '/employee_expenses/new';
            }}>
            <CardWallet />
            <span>{t('expenses.attributes.source_type.employee_expense')}</span>
          </Dropdown.SubMenu.Item>
        )}
        {can('create_km_expense') && (
          <Dropdown.SubMenu.Item
            onClick={() => {
              window.location.href = '/km_expenses/new';
            }}>
            <Car />
            <span>{t('expenses.attributes.source_type.km_expense')}</span>
          </Dropdown.SubMenu.Item>
        )}
        {can('create_external_expense') && (
          <Dropdown.SubMenu.Item
            onClick={() => {
              window.location.href = '/external_expenses/new';
            }}>
            <CreditCard />
            <span>{t('expenses.attributes.source_type.external_expense')}</span>
          </Dropdown.SubMenu.Item>
        )}
        {can('create_supplier_expense') && (
          <Dropdown.SubMenu.Item
            onClick={() => {
              window.location.href = '/supplier_expenses/new';
            }}>
            <PageFlip />
            <span>{t('expenses.attributes.source_type.supplier_expense')}</span>
          </Dropdown.SubMenu.Item>
        )}
      </Dropdown.SubMenu>
    </Dropdown>
  );
};

export default DropdownAdd;
