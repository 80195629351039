import React from 'react';
import MediaQuery from 'react-responsive';
import { useHits } from 'react-instantsearch';
import { Outlet } from 'react-router-dom';
import 'twin.macro';

import { Accordion, Checkbox } from '@new-components';
import { Stats, CurrentRefinements } from '@new-components/InstantSearch';

// TODO: Uncomment when back-end can handle `all` parameter
// import SelectAllHits from './SelectAllHits';
import TablePagination from './TablePagination';
import { useSelectedHits } from '../contexts/SelectedHits';
import { OpenedHitsContext, OpenedHitsProvider } from '../contexts/OpenedHits';

const ActionTab = () => {
  const { items } = useHits();
  const { selectedIds, selectMany } = useSelectedHits();
  const hasSelected = selectedIds.length > 0;

  return (
    <div tw="flex flex-col flex-1 min-w-0">
      <OpenedHitsProvider>
        <div tw="flex flex-wrap ml-4 my-[0.2rem] mr-10 gap-2 items-center">
          <MediaQuery minWidth={768}>
            <Checkbox
              isMaster
              checked={hasSelected}
              onChange={() => selectMany(hasSelected ? [] : items)}
            />
          </MediaQuery>
          <OpenedHitsContext.Consumer>
            {({ allOpened, openAll }) => (
              <Accordion.ToggleButton isOpen={allOpened} onClick={() => openAll(!allOpened)} />
            )}
          </OpenedHitsContext.Consumer>
          <Stats i18nKey="expenses.list.results" />
          <CurrentRefinements />
        </div>
        {/* <SelectAllHits /> */}
        <Outlet />
      </OpenedHitsProvider>
      <TablePagination />
    </div>
  );
};

export default ActionTab;
