import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Document, Page, DocumentProps } from 'react-pdf';
import tw from 'twin.macro';
import { useResizeObserver } from '@hooks';
import Badge from '../Badge';
import { PrevNextButtonGroup } from '../Pagination';
import FileLoadError from '../FileLoadError';

type Types = 'img' | 'pdf';

type PDFProps = DocumentProps & {
  pageNumber: number;
  width?: number;
};

export type Props = {
  src: string;
  type?: Types;
  href?: string;
  onErrorClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledPreview = tw.a`
  relative w-full h-full flex bg-blueGray-020 border border-gray-300 rounded-lg
`;

const PDFPreview = ({ file, onLoadError, onLoadSuccess, pageNumber, width }: PDFProps) => (
  <Document file={file} onLoadError={onLoadError} onLoadSuccess={onLoadSuccess} tw="m-auto">
    <Page
      pageNumber={pageNumber}
      renderTextLayer={false}
      renderAnnotationLayer={false}
      width={width}
    />
  </Document>
);

const buttonPageDisabled = (page: number, totalPages: number) => {
  if (totalPages <= 1) {
    return 'both';
  }
  if (page === totalPages) {
    return 'next';
  }
  return page === 1 ? 'prev' : undefined;
};

const FilePreview = ({ type = 'img', src, href, onErrorClick }: Props) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);

  const [width, setWidth] = useState<number>();
  const containerRef = useRef<HTMLAnchorElement>(null);

  const changePage = (e: React.MouseEvent<HTMLElement>, newPageNum: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (newPageNum > 0 && newPageNum <= totalPages) setPageNumber(newPageNum);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
    setError(false);
  };

  const onLoadError = () => {
    setError(true);
  };

  const onResize = useCallback<ResizeObserverCallback>(entries => {
    const [entry] = entries;

    if (entry) setWidth(entry.contentRect.width);
  }, []);

  useResizeObserver(containerRef.current, {}, onResize);

  useEffect(() => {
    setError(false);
  }, [src]);

  if (error) return <FileLoadError onClick={onErrorClick} />;

  return (
    <StyledPreview ref={containerRef} href={href} target="_blank">
      {type === 'pdf' || src?.endsWith('.pdf') ? (
        <>
          <PDFPreview
            file={src}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onLoadError}
            pageNumber={pageNumber}
            width={width}
          />
          <div tw="absolute inset-x-2.5 bottom-2.5 flex justify-between items-end">
            <PrevNextButtonGroup
              shade="primary"
              disabled={buttonPageDisabled(pageNumber, totalPages)}
              onClickNext={e => changePage(e, pageNumber + 1)}
              onClickPrev={e => changePage(e, pageNumber - 1)}
            />
            <span>
              <Badge color="dark-blue" shape="oval">
                {pageNumber}/{totalPages}
              </Badge>
            </span>
          </div>
        </>
      ) : (
        <img src={src} alt="Preview" tw="w-full object-contain" onError={onLoadError} />
      )}
    </StyledPreview>
  );
};

export default FilePreview;
