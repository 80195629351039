import React from 'react';

import { HitsTable as Hits } from '@new-components/InstantSearch';
import { EmptyStateMessage } from '@new-components';

import { ErrorState } from '../components';
import Hit from './Hit';
import SkeletonHit from './SkeletonHit';
import THead from './THead';

const AllExpenses = () => (
  <Hits
    hitComponent={Hit}
    thead={<THead />}
    skeleton={SkeletonHit}
    emptyMessage={<EmptyStateMessage />}
    errorMessage={<ErrorState />}
  />
);

export default AllExpenses;
