import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import 'twin.macro';

import { StaticRefinements } from '@new-components';
import { Stats, CurrentRefinements } from '@new-components/InstantSearch';

import type { Tab } from '../types';

import SelectAllHits from './SelectAllHits';
import TablePagination from './TablePagination';

const refinementKeys: { [k: string]: { label: string; values: string[] } } = {
  card: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.card'] },
  external: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.external'] },
  km: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.km'] },
  mine: { label: 'expenses.list.filters.user_name', values: ['{{username}}'] },
  supplier: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.supplier'] },
  employee: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.employee'] },
};

const ViewTab = ({ indexId }: { indexId: Tab }) => {
  const { t } = useTranslation();

  const username = document.querySelector('body')?.dataset.fullname;
  const staticRefinements = refinementKeys[indexId as string];

  return (
    <div tw="flex flex-col flex-1 min-w-0">
      <div tw="flex flex-wrap ml-4 my-3 mr-10 gap-2">
        <Stats i18nKey="expenses.list.results" />
        {staticRefinements && (
          <StaticRefinements
            items={[
              {
                label: t(staticRefinements.label),
                values: staticRefinements.values.map((v: string) => t(v, { username })),
              },
            ]}
          />
        )}
        <CurrentRefinements />
      </div>
      <SelectAllHits />
      <div tw="flex-1 overflow-auto">
        <Outlet />
      </div>
      <TablePagination />
    </div>
  );
};

export default ViewTab;
