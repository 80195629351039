import React from 'react';
import tw, { styled } from 'twin.macro';
import { XmarkCircle } from 'iconoir-react';

import Badge from '../Badge';

export type Props = React.PropsWithChildren<{
  label?: string;
  disabled?: boolean;
  onClickRemove?: React.MouseEventHandler<HTMLButtonElement>;
}>;

type StyledProps = {
  disabled?: boolean;
  $unchangeable: boolean;
};

const disabledStyles = 'cursor-default pr-2.5 bg-blueGray-100 hover:bg-blueGray-100';

const StyledChip = styled.span<StyledProps>`
  ${tw`flex flex-row items-center cursor-pointer`}
  ${tw`pl-2.5 pr-1 py-1 text-xs rounded-lg flex gap-1`}
  ${tw`text-blue-700 bg-blue-005 hover:bg-blue-010`}
  ${({ $unchangeable }) =>
    $unchangeable && tw`cursor-default pr-2.5 bg-yellow-050 hover:bg-yellow-050`}
  ${tw`group-disabled:(${disabledStyles})`}
  ${({ disabled }) => disabled && tw`${disabledStyles}`}

  > strong {
    ${tw`font-bold`}
  }

  > button {
    ${tw`group-disabled:hidden`}
    ${tw`text-blue-200 bg-transparent cursor-pointer`}

    > svg {
      ${tw`w-4 h-4`}
    }
  }

  ${Badge} {
    ${({ disabled }) => disabled && tw`text-blueGray-700 bg-white`}
  }
`;

const Chip: React.FC<Props> = ({ label, children, onClickRemove, ...props }) => (
  <StyledChip {...props} $unchangeable={!onClickRemove}>
    {label && <strong tw="text-nowrap">{label} :</strong>}
    <div tw="flex-1 truncate">{children}</div>
    {onClickRemove && (
      <button
        type="button"
        onClick={onClickRemove}
        aria-label="Remove"
        onMouseDown={e => e.preventDefault()}>
        <XmarkCircle />
      </button>
    )}
  </StyledChip>
);

export default Chip;
