import tw, { styled } from 'twin.macro';

export type Color = 'red' | 'orange' | 'green' | 'blue' | 'gray';

export type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

const colors = {
  red: tw`bg-red-050 text-red-500`,
  orange: tw`bg-orange-050 text-orange-700`,
  green: tw`bg-green-050 text-green-700`,
  blue: tw`bg-blue-005 text-blue-100`,
  gray: tw`bg-gray-100 text-blueGray-800`,
};

const sizes = {
  xl: tw`w-16 h-16 [>svg]:(w-8 h-8)`,
  lg: tw`w-12 h-12 [>svg]:(w-8 h-8)`,
  md: tw`w-9 h-9 [>svg]:(w-6 h-6)`,
  sm: tw`w-5 h-5 [>svg]:(w-4 h-4)`,
  xs: tw`w-4 h-4 [>svg]:(w-3 h-3)`,
};

const BadgeIcon = styled.span<{ color: Color; size?: Size }>`
  ${tw`inline-flex align-middle justify-center items-center rounded-full`}
  ${({ size = 'sm' }) => sizes[size]}
  ${({ color }) => colors[color]}
`;

export default BadgeIcon;
