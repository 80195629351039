import React from 'react';
import MediaQuery from 'react-responsive';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { BadgeCheck, CheckCircle, TriangleFlagTwoStripes, Check } from 'iconoir-react';
import tw, { styled } from 'twin.macro';
import { DEFAULT_CURRENCY } from '@constants';
import {
  type RadioButtonColor,
  BadgeIcon,
  Checkbox,
  Message,
  RadioButtonGroup,
  Tag,
  ContextField as Field,
} from '@new-components';
import { ToggleButton } from '@new-components/Accordion';
import Data from '../Data';
import TypeTag from '../TypeTag';
import ReceiptTag from '../ReceiptTag';

export type Props = {
  data: ExpenseHit & {
    isCorrectionCompleted?: boolean;
    isInCorrection?: boolean;
    isOutOfPolicy?: boolean;
  };
  onStepUp: (data: any) => void;
  nextStep: ExpenseStep;
  updatedStep: ExpenseStep;
  isStepUpdated: boolean;
  isSelected: boolean;
  onClickSelect: (selected: boolean) => void;
  isOpen: boolean;
  onClickOpen: () => void;
};

const stepToColor: Record<ExpenseStep, RadioButtonColor> = {
  paid: 'blue',
  verified: 'orange',
  controlled: 'orange',
  approved: 'green',
  rejected: 'red',
};

const StyledHeader = styled.div<{ $isSelected: boolean }>`
  ${tw`p-4 md:pr-10 relative flex flex-col gap-y-6 md:(flex-row)`}
  ${tw`bg-white hover:bg-blue-005 cursor-pointer`}
  ${({ $isSelected }) => $isSelected && tw`bg-blue-040 hover:bg-blue-040`}
`;

const Cell = styled.div`
  ${tw`flex flex-col flex-1 gap-2 `}
  ${tw`md:(w-1/3 min-w-[240px] h-full px-4 border-l border-solid border-l-blueGray-100)`}
`;

const Info = styled.div`
  ${tw`flex-1 flex flex-col gap-2 text-sm overflow-y-hidden overflow-x-auto`}
  ${tw`md:(flex-row items-center)`}
`;

export const Amount = ({
  amountReal,
  amountCurrency,
  sourceType,
  currency,
}: {
  amountReal: ExpenseHit['amount_real'];
  amountCurrency: ExpenseHit['amount_currency'];
  sourceType: ExpenseHit['source_type'];
  currency: ExpenseHit['currency'];
}) => {
  const { t } = useTranslation();
  const isPositive = amountReal > 0;
  const isReversed = sourceType === 'ReversedExpense';
  const isForeign = !!currency && currency !== DEFAULT_CURRENCY;

  return (
    <div tw="w-32 truncate">
      <span
        tw="font-heading font-semibold leading-tight text-xl min-w-[110px]"
        css={[isPositive && !isReversed && tw`text-positive`]}>
        {isPositive && '+'}
        {t('{{value, currency}}', {
          value: Math.abs(amountReal),
          currency: DEFAULT_CURRENCY,
        })}
      </span>
      {isForeign && (
        <span tw="block text-xs font-bold text-blueGray-700">
          {t('{{value, currency}}', {
            value: Math.abs(amountCurrency),
            currency,
          })}
        </span>
      )}
    </div>
  );
};

const AtAndBy = ({
  date,
  userName,
  userProfileId,
}: {
  date: ExpenseHit['date'];
  userName: ExpenseHit['user_name'];
  userProfileId: ExpenseHit['user_profile_id'];
}) => {
  const { t } = useTranslation();

  return (
    <span tw="truncate">
      {t('expenses.list.accordion.at', { date: new Date(date * 1000) })}{' '}
      <Trans i18nKey="expenses.list.accordion.by" values={{ name: userName }}>
        by
        <a
          href={`/employees/${userProfileId}/edit`}
          target="_blank"
          rel="noreferrer"
          tw="inline text-primary-2 font-semibold"
          onClick={e => e.stopPropagation()}>
          {userName}
        </a>
      </Trans>
    </span>
  );
};

const StepsField = ({
  name,
  current,
  next,
  updated,
  onChange,
  disabled = false,
  isStepUpdated = false,
}: {
  name: string;
  current: ExpenseStep;
  next: ExpenseStep;
  updated: ExpenseStep;
  onChange: (data: any) => void;
  disabled?: boolean;
  isStepUpdated?: boolean;
}) => {
  const { t } = useTranslation();
  const { handleSubmit, trigger, setValue } = useFormContext();

  if (isStepUpdated) {
    return (
      <div tw="bg-inherit p-4 absolute top-0 right-6 left-auto md:(bottom-0)">
        <Tag color="green">
          <span tw="mr-1">{t(`expenses.attributes.step.${next}`)}</span>
          <BadgeCheck tw="w-3.5 h-3.5" />
        </Tag>
      </div>
    );
  }

  if (current === next) return null;

  return (
    <div tw="bg-inherit md:(p-4 absolute inset-y-0 right-6 left-auto)">
      <Field
        name={name}
        render={({ field }) => {
          return (
            <RadioButtonGroup
              size="lg"
              onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
              <RadioButtonGroup.Radio
                checked={updated === current}
                name={name}
                value={current}
                readOnly>
                <span>{t(`expenses.attributes.step.${current}`)}</span>
              </RadioButtonGroup.Radio>
              <RadioButtonGroup.Radio
                name={name}
                onChange={e => {
                  field.onChange(e);
                  trigger().then(res => {
                    if (res) return handleSubmit(onChange)();
                    return setValue(name, current);
                  });
                }}
                value={next}
                color={stepToColor[next]}
                checked={updated === next}
                disabled={disabled}>
                <span>{t(`expenses.attributes.step.${next}`)}</span>
              </RadioButtonGroup.Radio>
            </RadioButtonGroup>
          );
        }}
      />
    </div>
  );
};

const Title = ({ children }: React.ComponentProps<'span'>) => {
  const title = useWatch({ name: 'title' });

  return <span tw="truncate">{title || children}</span>;
};

const ExpenseCategory = ({ children }: React.ComponentProps<'span'>) => {
  const expenseCategory = useWatch({ name: 'expenseCategory' });

  return <span tw="truncate">{expenseCategory?.label || children}</span>;
};

const Header = ({
  data,
  updatedStep,
  nextStep,
  onStepUp,
  isStepUpdated,
  isSelected = false,
  onClickSelect,
  isOpen = false,
  onClickOpen,
}: Props) => {
  const { t } = useTranslation();
  const { isOutOfPolicy, isInCorrection, isCorrectionCompleted } = data;

  return (
    <StyledHeader $isSelected={isSelected} onClick={onClickOpen}>
      <div tw="flex items-center gap-2 mr-2">
        {isStepUpdated ? (
          <BadgeIcon color="green" size="md" tw="-mx-2">
            <Check />
          </BadgeIcon>
        ) : (
          <MediaQuery minWidth={768}>
            <Checkbox
              checked={isSelected}
              onChange={(e: any) => onClickSelect(e.target.checked)}
              onClick={e => e.stopPropagation()}
            />
          </MediaQuery>
        )}
        <ToggleButton isOpen={isOpen} />
        <Amount
          amountReal={data.amount_real}
          sourceType={data.source_type}
          amountCurrency={data.amount_currency}
          currency={data.currency}
        />
      </div>
      <Info tw="w-full h-[58px] md:pr-56">
        <Cell tw="flex-col-reverse md:(flex-col)">
          <AtAndBy
            date={data.date}
            userName={data.user_name}
            userProfileId={data.user_profile_id}
          />
          <span>
            <TypeTag value={data.type} />
          </span>
        </Cell>
        <Cell>
          <Title>
            <Data fallback={data.merchant_name}>{data.title}</Data>
          </Title>
          <ExpenseCategory>
            <Data fallback={t('globals.undefined')}>{data.expense_category}</Data>
          </ExpenseCategory>
        </Cell>
        <Cell tw="gap-4 flex md:(flex-row gap-8)">
          <div tw="flex flex-row gap-2 md:(flex-col)">
            <span>{t('expenses.list.table.receipt')}</span>
            <span tw="min-w-[100px]">
              <ReceiptTag value={data.receipt} />
            </span>
          </div>
          {(isOutOfPolicy || isInCorrection || isCorrectionCompleted) && (
            <span tw="flex justify-items-start items-center">
              {isOutOfPolicy && (
                <Message
                  size="sm"
                  variant="warning"
                  icon={<TriangleFlagTwoStripes />}
                  tw="font-semibold whitespace-nowrap">
                  {t('expenses.list.filters.out_of_company_policies.out_of_policy')}
                </Message>
              )}
              {isInCorrection && (
                <Message
                  size="sm"
                  variant="warning"
                  icon={<TriangleFlagTwoStripes />}
                  tw="font-semibold whitespace-nowrap">
                  {t('expenses.list.table.active_correction_request')}
                </Message>
              )}
              {isCorrectionCompleted && (
                <Message
                  size="sm"
                  variant="success"
                  icon={<CheckCircle />}
                  tw="font-semibold whitespace-nowrap">
                  {t('expenses.list.table.corrected')}
                </Message>
              )}
            </span>
          )}
        </Cell>
      </Info>
      <StepsField
        name="step"
        next={nextStep}
        updated={updatedStep}
        isStepUpdated={isStepUpdated}
        current={(data.step.split('_').pop() || 'paid') as ExpenseStep}
        onChange={onStepUp}
        disabled={isInCorrection}
      />
    </StyledHeader>
  );
};

export default Header;
