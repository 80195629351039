import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningCircle } from 'iconoir-react';
import 'twin.macro';

import { Button, Form, Modal, BadgeIcon } from '@new-components';
import { useCancelCorrectionRequest } from '@hooks/useExpenses';
import { toastify } from '@helpers/toastify';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  expenseId: string | null;
};

const CancelCorrectionRequestModal = ({ isOpen, onClose, expenseId }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: cancelCorrectionRequest, isLoading } = useCancelCorrectionRequest(
    expenseId || '',
  );

  const onSubmit = async () => {
    if (!expenseId) return;

    try {
      await cancelCorrectionRequest();
      toastify('success', t('correction_request.delete.success'));
      onClose();
    } catch (err) {
      toastify('error', t('globals.error_occurred'));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <BadgeIcon size="xl" color="orange">
            <WarningCircle tw="w-8 h-8 text-orange-700 m-auto" />
          </BadgeIcon>
          <h3 tw="text-orange-700 mt-2">{t('expenses.cancel_correction_request.title')}</h3>
        </Modal.Header>
        <hr tw="my-6" />
        <Modal.Body>
          <p tw="mb-6 text-blueGray-700 text-center">
            {t('expenses.cancel_correction_request.message')}
          </p>
        </Modal.Body>
        <Modal.Footer tw="pb-4 flex gap-x-4">
          <Button size="md" shade="secondary" tw="w-full justify-center" onClick={onClose}>
            {t('globals.cancel')}
          </Button>
          <Button type="submit" tw="w-full justify-center" isLoading={isLoading}>
            {t('globals.confirm')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CancelCorrectionRequestModal;
