import React from 'react';
import tw, { styled } from 'twin.macro';

export type Size = 'md' | 'lg';

export type Props = React.ComponentProps<'div'> & {
  size?: Size;
  color?: Color;
};

export type RadioProps = React.ComponentProps<'input'> & {
  color?: Color;
};

export type Color = 'blue' | 'orange' | 'gold' | 'green' | 'red';

const sizes = {
  md: tw`py-2.5 px-2 gap-2.5 text-sm`,
  lg: tw`py-4.5 px-4 gap-2.5 text-sm`,
};

const colors = {
  blue: tw`text-blue-200 bg-blue-005 outline-blue-050`,
  orange: tw`text-orange-700 bg-orange-050 outline-orange-080`,
  gold: tw`text-gold-800 bg-gold-050 outline-gold-100`,
  green: tw`text-green-700 bg-green-040 outline-green-080`,
  red: tw`text-red-700 bg-red-50 outline-red-100`,
};

const StyledRadio = styled.div<{ $color?: Color }>`
  ${tw`flex items-center justify-center gap-1.5 truncate`}
  ${tw`cursor-pointer shadow-none`}
  ${tw`font-semibold text-blue-500 bg-white border-blueGray-100 border`}

  input:disabled + & {
    ${tw`bg-blueGray-050 text-blueGray-300 border-blueGray-100 border`}
    ${tw`cursor-default`}
  }

  input:not(:disabled):checked + & {
    ${tw`font-semibold text-blue-500 bg-blueGray-080 border outline-blueGray-100 shadow-inner`}
    ${tw`outline outline-1 outline-offset-[-1px]`}
    ${({ $color }) => $color && colors[$color]}
  }

  input:not(:disabled):hover + & {
    ${tw`font-semibold shadow-inner bg-blueGray-080`}
    ${tw`outline outline-1 outline-offset-[-1px] outline-blueGray-100`}
    ${({ $color }) => $color && colors[$color]}
  }
`;

const StyledRadioButtonGroup = styled.div<{ $size: Size }>`
  ${tw`flex p-0 gap-0 [>label]:flex-1`}

  ${StyledRadio} {
    ${({ $size }) => $size && sizes[$size]}
  }

  & > label:first-child ${StyledRadio} {
    ${tw`rounded-l-lg flex-1`}
  }

  & > label:last-child ${StyledRadio} {
    ${tw`rounded-r-lg flex-1`}
  }

  // Remove left border for non-first children
  & > label:not(:first-child) ${StyledRadio} {
    ${tw`border-l-0`}
  }
`;

const Radio = ({ children, color, ...props }: RadioProps) => (
  <label>
    <input {...props} type="radio" tw="sr-only" />
    <StyledRadio $color={color}>{children}</StyledRadio>
  </label>
);

const RadioButtonGroup = ({ children, size = 'md', ...props }: Props) => (
  <StyledRadioButtonGroup {...props} $size={size}>
    {children}
  </StyledRadioButtonGroup>
);

RadioButtonGroup.Radio = Radio;

export default RadioButtonGroup;
