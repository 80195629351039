/* eslint-disable no-underscore-dangle */
import React from 'react';
import 'twin.macro';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ContextField as Field, Select, Label, InputText } from '@new-components';

import { FieldStates } from '../../constants';
import { fieldProps, loadOptions } from './utils';

type FieldProps = {
  required?: boolean;
  disabled?: boolean;
};

type Props = React.ComponentPropsWithoutRef<'fieldset'> & {
  expenseId: string;
  analyticalAxes: Expense['analyticalAxes'];
  fields: Record<string, FieldStates>;
};

const BusinessCodeField = ({ expenseId, ...props }: FieldProps & { expenseId: string }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label tw="mb-1" hasRequired={props.required}>
        {t('expenses.list.filters.business_code')}
      </Label>
      <Field name="businessCode" label={t('expenses.list.filters.business_code')} {...props}>
        <Select loadOptions={loadOptions(expenseId, 'business_code')} isClearable />
      </Field>
    </div>
  );
};

const AnalyticCodeField = ({ expenseId, ...props }: FieldProps & { expenseId: string }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label hasRequired={props.required} tw="mb-1">
        {t('expenses.list.filters.analytic_code')}
      </Label>
      <Field name="analyticCodes" label={t('expenses.list.filters.analytic_code')} {...props}>
        <Select isMulti loadOptions={loadOptions(expenseId, 'analytic_code')} />
      </Field>
    </div>
  );
};

const AnalyticalAxeField = ({
  expenseId,
  axe,
  required,
  index,
  ...props
}: FieldProps & {
  expenseId: string;
  axe: ExpenseAnalyticalAxis;
  index: number;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Label tw="mb-1" hasRequired={required}>
        {axe.name}
      </Label>
      <Field
        name={`analyticalAxes[${index}].value`}
        defaultValue=""
        required={required && t('globals.errors.required', { attribute: axe.name })}
        {...props}>
        {axe.type === 'free_values' ? (
          <InputText />
        ) : (
          <Select
            loadOptions={loadOptions(expenseId, ['analytical_axis_value', axe.id])}
            isClearable
          />
        )}
      </Field>
    </div>
  );
};

const AnalyticalAxesFields = ({
  expenseId,
  axes,
  ...props
}: {
  expenseId: string;
  axes: Expense['analyticalAxes'];
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields } = useFieldArray({ control, name: 'analyticalAxes', keyName: '_id' });

  return (
    <div>
      <h6 tw="mb-2">{t('expenses.list.form.analytical_axes.label')}</h6>
      <div tw="flex flex-col gap-y-2">
        {(fields || []).map((field, index) => (
          <AnalyticalAxeField
            key={field._id}
            expenseId={expenseId}
            axe={field as unknown as ExpenseAnalyticalAxis}
            index={index}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

const AccountingFieldset = ({ expenseId, analyticalAxes, fields, ...props }: Props) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { business_code_name, analytic_code_names, analytical_axis } = fields || {};

  if (!business_code_name && !analytic_code_names && !analytical_axis) return null;

  return (
    <fieldset {...props} className="group">
      <h5 tw="mb-2">{t('expenses.list.filters.accountability')}</h5>
      <div tw="flex flex-col gap-y-4">
        {business_code_name && (
          <BusinessCodeField expenseId={expenseId} {...fieldProps(business_code_name)} />
        )}
        {analytic_code_names && (
          <AnalyticCodeField expenseId={expenseId} {...fieldProps(analytic_code_names)} />
        )}
        {analytical_axis && analyticalAxes && (
          <AnalyticalAxesFields
            expenseId={expenseId}
            axes={analyticalAxes}
            {...fieldProps(analytical_axis)}
          />
        )}
      </div>
    </fieldset>
  );
};

export default AccountingFieldset;
