import { apiClient } from '../constants';

export const getContactsSearch = () =>
  apiClient.get<PaginatedSearchRequest<Contact>, PaginatedSearchRequest<Contact>>(
    '/contacts/search',
  );

export const searchContacts = (params?: ApiParams) =>
  apiClient.post<PaginatedSearchRequest<Contact>, PaginatedSearchRequest<Contact>>(
    '/contacts/search',
    params,
  );

export const deleteContactSearch = () =>
  apiClient.delete<PaginatedSearchRequest<Contact>, PaginatedSearchRequest<Contact>>(
    '/contacts/search',
  );

export const deleteContact = (id: Contact['id']) => apiClient.delete(`/contacts/${id}.json`);

export const addContacts = (params?: ContactForm) =>
  apiClient.post<Contact, Contact>('/contacts', params);

export const importContactsFile = (formData: FormData) => {
  return apiClient.post('/contacts/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateContact = (id: string, params: ContactForm) =>
  apiClient.patch<Contact, Contact>(`/contacts/${id}.json`, params);

export const getContact = (id?: string) => apiClient.get(`/contacts/${id}.json`);
