import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import axios from 'axios';
import * as z from 'zod';

import { expenseToFormValues } from '@api/expenses/helpers';
import { toastify } from '@helpers/toastify';
import { useGetExpense, useGetFieldsToDisplay, useUpdateExpense } from '@hooks/useExpenses';
import { Accordion, Form } from '@new-components';

import { ActionTabs, nextSteps, FieldStates } from '../../constants';
import { useOpenedHits } from '../../contexts/OpenedHits';
import { useSelectedHits } from '../../contexts/SelectedHits';
import { useActionHits } from '../../contexts/ActionHits';
import Header from './Header';
import Content from './Content';

export type Props = {
  hit: ExpenseHit;
};

const isRequired = (state: FieldStates) => state === FieldStates.required;

const validateVatNumber = (
  value: string,
  {
    companyVatNumber,
    countryVatPatterns,
  }: Pick<Expense, 'companyVatNumber' | 'countryVatPatterns'>,
) => {
  const number = value.toUpperCase().trim().replace(/\s/g, '');

  if (!number) return true;
  if (number === companyVatNumber) return 'same_vat_number_as_company';
  if (number.length <= 2) return 'invalid_vat_number';

  const alpha2 = number.substring(0, 2);
  const country = countryVatPatterns.find(v => v.alpha2 === alpha2);

  if (!country) return 'invalid_vat_number';
  if (!number.match(country.regex)) return 'invalid_vat_number';

  return true;
};

const schema = (
  t: any,
  step: ExpenseStep,
  nextStep: ExpenseStep,
  { companyVatNumber, countryVatPatterns }: Expense,
  fields?: Record<string, FieldStates>,
) => {
  const treq = (attribute: string) => t('globals.errors.required', { attribute });

  const znum = z.union([z.string(), z.number()]);
  const zopt = (message?: string) =>
    z.object({ label: z.string(), value: z.string() }, { message });

  const zreqStr = (attribute: string) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const required_error = treq(attribute);

    return z.string({ invalid_type_error: required_error, required_error }).min(1, required_error);
  };
  const zreqNum = (attribute: string) => zreqStr(attribute).or(z.number().min(1, treq(attribute)));

  const def = z.object({
    step: z.string(),
    withoutReceipt: z.boolean(),
    title: zreqStr(t('expenses.list.table.title')),
    expenseCategory: zopt().nullable(),
    department: zopt().nullable(),
    vehicle: zopt().nullable(),
    vehicleFuelVolume: znum,
    vehicleKilometers: znum,
    businessCode: zopt().nullable(),
    analyticCodes: z.array(zopt()),
    analyticalAxes: z.array(z.object({ id: z.string(), value: z.string().nullable().or(zopt()) })),
    internalAttendees: z.array(zopt()),
    externalAttendees: z.array(zopt()),
    vatNumber: z.string().superRefine((val, ctx) => {
      const res = validateVatNumber(val, { companyVatNumber, countryVatPatterns });

      if (res !== true)
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t(`expenses.list.form.vat_number.errors.${res}`),
        });
    }),
    invoiceCountry: z.string(),
    amountTouristTax: znum,
    vats: z.array(z.object({ vatRateId: z.string(), rate: znum, amount: znum })),
  });

  const opt = z.object({
    step: z.literal(step),
  });

  // if no "required" fields
  if (!fields || step === nextStep) return def.optional();

  const tAxe = t('expenses.list.filters.analytical_axe');
  const req = z.object({
    step: z.literal(nextStep),
    expenseCategory: isRequired(fields.expense_category)
      ? zopt(treq(t('expenses.list.filters.expense_category')))
      : zopt().nullable(),
    department: isRequired(fields.department)
      ? zopt(treq(t('expenses.list.filters.department')))
      : zopt().nullable(),
    vehicle: isRequired(fields.vehicle)
      ? zopt(treq(t('expenses.list.filters.vehicle_name')))
      : zopt().nullable(),
    vehicleFuelVolume: isRequired(fields.vehicle_fuel_volume)
      ? zreqNum(t('expenses.list.form.vehicle_fuel_volume.label'))
      : znum,
    vehicleKilometers: isRequired(fields.vehicle_kilometers)
      ? zreqNum(t('expenses.list.form.vehicle_kilometers.label'))
      : znum,
    businessCode: isRequired(fields.business_code_name)
      ? zopt(treq(t('expenses.list.filters.business_code')))
      : zopt().nullable(),
    analyticCodes: isRequired(fields.analytic_code_names)
      ? z.array(zopt()).min(1, treq(t('expenses.list.filters.analytic_code')))
      : z.array(zopt()),
    analyticalAxes: isRequired(fields.analytical_axis)
      ? z.array(z.object({ id: z.string(), value: zreqStr(tAxe).or(zopt(tAxe)) }))
      : z.array(z.object({ id: z.string(), value: z.string().nullable().or(zopt()) })),
  });

  const union = z.discriminatedUnion('step', [opt, req]);
  const inter = z.intersection(def, union);

  // eslint-disable-next-line consistent-return
  return inter.superRefine((data, ctx) => {
    const atts = data.internalAttendees.concat(data.externalAttendees);

    // if internal_attendee_names is required, external_attendee_names is also required
    if (fields.internal_attendee_names === FieldStates.required && !atts.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('globals.errors.required', {
          attribute: t('expenses.list.filters.internal_attendees'),
        }),
        path: ['internalAttendees'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('globals.errors.required', {
          attribute: t('expenses.list.filters.external_attendees'),
        }),
        path: ['externalAttendees'],
      });
    }
  });
};

const WatchExpenseCategory = ({ callback }: { callback: (id: string) => void }) => {
  const expenseCategory = useWatch({ name: 'expenseCategory' });

  useEffect(() => {
    if (expenseCategory?.value) callback(expenseCategory?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseCategory?.value]);

  return null;
};

const Hit = ({ hit }: Props) => {
  const { t } = useTranslation();
  const { indexId, isSelected, toggleSelect, unselect, allSelected } = useSelectedHits();
  const { allOpened } = useOpenedHits();
  const { is, set } = useActionHits();

  const nextStep = nextSteps[indexId as ActionTabs];
  const [step, setStep] = useState<ExpenseStep>(
    (hit.step.split('_').pop() || 'paid') as ExpenseStep,
  );

  const [isHitOpen, setIsHitOpen] = useState(allOpened);
  const isHitSelected = isSelected(hit) || allSelected;
  const isHitSteppedUp = is(nextStep, hit);

  const [expenseCategoryId, setExpenseCategoryId] = useState<string | undefined>(undefined);

  const { mutateAsync: update, isLoading } = useUpdateExpense();
  const { data: expense } = useGetExpense(hit.objectID, { enabled: isHitOpen });
  const {
    data: { fields, vatDeductiblePercent },
  } = useGetFieldsToDisplay(hit.objectID, nextStep, expenseCategoryId, {
    enabled: !!expense?.id,
    placeholderData: { fields: undefined },
    keepPreviousData: true,
  }) as { data: { fields: Record<string, FieldStates>; vatDeductiblePercent: number } };

  useEffect(() => setIsHitOpen(allOpened), [allOpened]);

  const eExpenseCategoryId = expense?.expenseCategory?.id;
  useEffect(() => {
    if (eExpenseCategoryId && expenseCategoryId !== eExpenseCategoryId)
      setExpenseCategoryId(eExpenseCategoryId);
  }, [eExpenseCategoryId]);

  const onSubmit = async (values: any) => {
    try {
      await update({ id: hit.objectID, params: values });

      if (values.step && values.step !== step) {
        setStep(values.step);
        set(values.step, hit);
        setIsHitOpen(false);
        unselect(hit);
      }
      toastify('success', t('expenses.update.success'));
    } catch (e: unknown) {
      const d = axios.isAxiosError(e) ? e.response?.data : e;
      const message = Array.isArray(d.errors) ? d.errors.join(', ') : d?.errors;

      toastify('error', message || t('globals.error_occurred'));
    }
  };

  const onStepUp = (data: any) => {
    if (isHitOpen) return onSubmit(data);
    return onSubmit({ step: nextStep });
  };

  const isStepUpdated = step !== (hit.step.split('_').pop() || 'paid') || isHitSteppedUp;

  const hdata = useMemo(
    () => ({
      ...hit,
      isCorrectionCompleted: expense?.isCorrectionCompleted,
      isInCorrection: !!expense?.isInCorrection || hit.active_correction_request,
      isOutOfPolicy: expense?.isOutOfCompanyPolicy || hit.out_of_company_policy !== 'none',
    }),
    [hit, expense?.isOutOfCompanyPolicy, expense?.isCorrectionCompleted, expense?.isInCorrection],
  );

  const cdata = useMemo(
    () => ({
      ...((expense || {}) as Expense),
      merchantName: hit.merchant_name,
      editLink: hit.edit_link,
      sourceType: hit.source_type,
    }),
    [expense, hit.merchant_name, hit.edit_link, hit.source_type],
  );

  const defaultValues = useMemo(() => expenseToFormValues({ ...cdata, step }), [cdata, step]);

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      schema={isHitOpen ? schema(t, step, nextStep, cdata, fields) : undefined}>
      <WatchExpenseCategory callback={setExpenseCategoryId} />
      <Accordion isOpen={isHitOpen}>
        <Accordion.Header>
          <Header
            data={hdata}
            nextStep={nextStep}
            updatedStep={step}
            onStepUp={onStepUp}
            isStepUpdated={isStepUpdated}
            isSelected={isHitSelected}
            onClickSelect={() => toggleSelect(hit)}
            isOpen={isHitOpen}
            onClickOpen={() => setIsHitOpen(!isHitOpen)}
          />
        </Accordion.Header>
        <Accordion.Content>
          {isHitOpen && (
            <Content
              data={cdata}
              fields={fields}
              vatDeductiblePercent={vatDeductiblePercent}
              isLoading={isLoading}
            />
          )}
        </Accordion.Content>
      </Accordion>
    </Form>
  );
};

export default Hit;
