import React from 'react';
import tw from 'twin.macro';

import useForwardRef from '@hooks/useForwardRef';

import InputWrapper, { Props as InputWrapperProps } from '../InputWrapper';

export type Props = React.ComponentProps<'textarea'> &
  Omit<InputWrapperProps, 'children' | 'isFocus' | 'isDisabled'>;

const TextArea = React.forwardRef(
  ({ css, size, hasError, error, icon, rightEl, ...props }: Props, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const inputRef = useForwardRef<HTMLTextAreaElement>(
      ref as React.RefObject<HTMLTextAreaElement>,
    );

    const focus = () => inputRef.current?.focus();

    return (
      <InputWrapper
        css={[css, tw`items-start`]}
        size={size}
        hasError={hasError}
        error={error}
        isFocused={isFocused}
        isDisabled={props.disabled}
        icon={icon}
        rightEl={rightEl}
        onClick={focus}>
        <textarea
          {...props}
          ref={inputRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </InputWrapper>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
