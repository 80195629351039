import React from 'react';
import { EmptyState } from '@assets/images';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import TableStateMessage from '../TableStateMessage';

const StyledMessage = styled.div`
  ${tw`text-sm text-center space-y-2`}
`;

const EmptyStateMessage = () => {
  const { t } = useTranslation();

  return (
    <TableStateMessage imageSrc={EmptyState}>
      <StyledMessage>
        <h6 tw="text-blue-500 block">{t('globals.search_empty_results')}</h6>
        <span tw="text-blueGray-700 block">{t('globals.search_change')}</span>
      </StyledMessage>
    </TableStateMessage>
  );
};

export default EmptyStateMessage;
