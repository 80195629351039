import React from 'react';
import { Configure } from 'react-instantsearch';

import { HitsTable as Hits } from '@new-components/InstantSearch';
import { EmptyStateMessage } from '@new-components';

import { ErrorState, TrHit as Hit, SkeletonHit, THead } from '../components';

const EmployeeExpenses = () => (
  <>
    <Configure filters="source_type:EmployeeExpense" />
    <Hits
      hitComponent={Hit}
      thead={<THead />}
      skeleton={SkeletonHit}
      emptyMessage={<EmptyStateMessage />}
      errorMessage={<ErrorState />}
    />
  </>
);

export default EmployeeExpenses;
