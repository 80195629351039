import React from 'react';
import { PageFlip } from 'iconoir-react';
import tw, { styled } from 'twin.macro';
import { t } from 'i18next';

const FieldWrapper = styled.div`
  ${tw`relative w-full h-full flex flex-col items-center justify-center p-6`}
  ${tw`border border-blueGray-100 rounded-lg`}
  ${tw`text-xs text-center bg-blueGray-020`}
`;

const IconWrapper = styled.div`
  ${tw`
    relative flex justify-center
    text-blueGray-300
    pb-4
  `}
`;

const FileMissingPreview = () => (
  <FieldWrapper>
    <IconWrapper>
      <PageFlip tw="w-[74px] h-[74px]" />
    </IconWrapper>
    <span tw="text-blue-700 text-xs font-semibold pb-6">
      {t('file_missing_preview.missing_receipt')}
    </span>
  </FieldWrapper>
);

export default FileMissingPreview;
