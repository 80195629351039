import React from 'react';
import 'twin.macro';
import { LineTripOverview } from '@assets/images';

export type Props = React.ComponentProps<'div'> & {
  startAddress: string;
  endAddress: string;
  distance: number;
};

const TripOverview = ({ startAddress, endAddress, distance, ...props }: Props) => (
  <div tw="flex" {...props}>
    <img src={LineTripOverview} alt="Line" loading="lazy" />
    <div tw="flex flex-col justify-between ml-2 text-xs">
      <strong tw="font-semibold">{startAddress}</strong>
      <span tw="text-blueGray-700">{`${distance} km`}</span>
      <strong tw="font-semibold">{endAddress}</strong>
    </div>
  </div>
);

export default TripOverview;
