export const toggleAttributes = ['type'];

export const rangeAttributes = ['amount', 'date', 'due_at'];

export const refinementListAttributes = [
  'card_token',
  'user_name',
  'expense_category',
  'receipt',
  'step',
  'complete',
  'department_name',
  'accountability',
  'accessible_by',
  'billable',
  'business_code',
  '_tags',
  'merchant_name',
  'location',
  'settled',
  'driver_code',
  'license_plate',
  'source_type',
  'reimbursement_id',
  'reimbursement_status',
];

export enum ViewTabs {
  all = 'all',
  card = 'card',
  employee = 'employee',
  km = 'km',
  external = 'external',
  supplier = 'supplier',
  mine = 'mine',
}

export enum ActionTabs {
  to_control = 'to_control',
  to_approve = 'to_approve',
}

export enum Modes {
  view = 'view',
  action = 'action',
}

export const nextSteps: Record<ActionTabs, Extract<ExpenseStep, 'controlled' | 'approved'>> = {
  to_control: 'controlled',
  to_approve: 'approved',
};

export enum FieldStates {
  optional = 'optional',
  required = 'required',
  disabled = 'disabled',
}
