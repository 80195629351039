import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import 'twin.macro';

import { useModal } from '@contexts/Modal';
import { ContextField as Field, Select, Label } from '@new-components';

import { FieldStates } from '../../constants';
import { fieldProps, loadAttendeesOptions } from './utils';

type Props = {
  fields: Record<string, FieldStates>;
  disabled?: boolean;
  required?: boolean;
  activeAt: Date;
};

type FieldProps = Pick<Props, 'disabled' | 'required' | 'activeAt'>;

const InternalAttendeesField = ({ disabled, required, activeAt }: FieldProps) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext();

  const {
    state: { props },
    openModal,
  } = useModal();

  const field = 'internalAttendees';
  const label = t('expenses.list.filters.internal_attendees');

  return (
    <div>
      <Label hasRequired={required} tw="mb-1">
        {label}
      </Label>
      <Field name={field} label={label} onBlur={() => trigger('externalAttendees')}>
        <Select
          isMulti
          loadOptions={loadAttendeesOptions(true, activeAt)}
          onCreateOption={name => openModal('addAttendee', { type: 'internal', name })}
          placeholder={t('globals.select')}
          cacheUniqs={[props.name]}
          disabled={disabled}
        />
      </Field>
    </div>
  );
};

const ExternalAttendeesField = ({ disabled, required, activeAt }: FieldProps) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext();
  const {
    state: { props },
    openModal,
  } = useModal();

  const field = 'externalAttendees';
  const label = t('expenses.list.filters.external_attendees');

  return (
    <div>
      <Label hasRequired={required} tw="mb-1">
        {label}
      </Label>
      <Field name={field} label={label} onBlur={() => trigger('internalAttendees')}>
        <Select
          isMulti
          loadOptions={loadAttendeesOptions(false, activeAt)}
          onCreateOption={name => openModal('addAttendee', { type: 'external', name })}
          placeholder={t('globals.select')}
          cacheUniqs={[props?.name]}
          disabled={disabled}
        />
      </Field>
    </div>
  );
};

const AttendeesFields = ({ fields, activeAt }: Props) => (
  <>
    {fields.internal_attendee_names && (
      <InternalAttendeesField {...fieldProps(fields.internal_attendee_names)} activeAt={activeAt} />
    )}
    {fields.external_attendee_names && (
      <ExternalAttendeesField {...fieldProps(fields.external_attendee_names)} activeAt={activeAt} />
    )}
  </>
);

export default AttendeesFields;
