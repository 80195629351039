import React from 'react';
import { toast, Id, UpdateOptions, ToastOptions } from 'react-toastify';
import Toast, { Variant } from '@new-components/Toast';

const updateToast = (toastId: Id | undefined, message: string, options: UpdateOptions) => {
  if (toastId) {
    toast.update(toastId, {
      ...options,
      render: message,
      isLoading: false,
    });
  }
};

export const updateToastSuccess = (toastId: Id | undefined, message: string) =>
  updateToast(toastId, message, { type: 'success', autoClose: 5000 });

export const updateToastError = (toastId: Id | undefined, message: string) =>
  updateToast(toastId, message, { type: 'error', closeButton: true });

export const toastify = (
  variant: Variant,
  message: React.ReactNode | string,
  options: ToastOptions<{}> = {},
) =>
  toast(<Toast variant={variant}>{message}</Toast>, {
    ...{ autoClose: 5000, ...options },
    className: variant,
  });
