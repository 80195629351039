import React from 'react';

import type { TagColor } from '@new-components';
import { Tag } from '@new-components';
import { useTranslation } from 'react-i18next';

type Props = {
  value: ExpenseHit['step'];
};

export const valueToColor: Record<ExpenseHit['step'], TagColor> = {
  '0_paid': 'dark-blue',
  '1_verified': 'orange',
  '2_controlled': 'orange',
  '3_approved': 'green',
  '4_rejected': 'red',
};

const StepTag = ({ value }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'expenses.attributes.step',
  });

  const color = valueToColor[value];
  const v = value.split('_').pop() || 'paid';

  return <Tag color={color}>{t(v)}</Tag>;
};

export default StepTag;
