import React from 'react';
import { useTranslation } from 'react-i18next';
import 'twin.macro';

import { Skeleton, Tr } from '@new-components';

const SkeletonHit = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expenses.list.table' });

  return (
    <Tr>
      <td tw="w-12 max-w-12 p-1.5" aria-label="Select">
        <Skeleton tw="w-4 h-4" />
      </td>
      <td tw="w-36 max-w-36" aria-label={t('user')}>
        <Skeleton />
      </td>
      <td tw="w-36 max-w-36" aria-label={t('supplier')}>
        <Skeleton />
      </td>
      <td tw="w-28 max-w-28" aria-label={t('invoice_date')}>
        <Skeleton />
      </td>
      <td tw="w-28 max-w-28" aria-label={t('due_at')}>
        <Skeleton />
      </td>
      <td tw="w-44 max-w-44" aria-label="Status">
        <Skeleton />
      </td>
      <td tw="w-24 max-w-24" aria-label={t('step')}>
        <Skeleton />
      </td>
      <td tw="w-24 max-w-24" aria-label={t('payment_status')}>
        <Skeleton />
      </td>
      <td tw="w-24 max-w-24" aria-label={t('amount')}>
        <Skeleton />
      </td>
    </Tr>
  );
};

export default SkeletonHit;
