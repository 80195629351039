/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { HelpCircleSolid } from 'iconoir-react';
import tw from 'twin.macro';

import Tooltip from '../Tooltip';

export type Props = React.ComponentProps<'div'> & {
  tooltip?: string;
  hasRequired?: boolean;
  htmlFor?: string;
};

const TooltipHelper = ({ tooltip }: { tooltip: string }) => (
  <Tooltip.Wrapper tw="flex text-label">
    <Tooltip arrow="center">{tooltip}</Tooltip>
    <HelpCircleSolid tw="w-3.5 h-3.5 text-blueGray-700 hover:text-primary-2" />
  </Tooltip.Wrapper>
);

const Label = ({ tooltip, children, hasRequired = false, htmlFor, ...props }: Props) => {
  return (
    <div tw="flex items-center gap-1" {...props}>
      <label tw="text-label" css={[hasRequired && tw`has-required`]} htmlFor={htmlFor}>
        {children}
      </label>
      {!!tooltip && <TooltipHelper tooltip={tooltip} />}
    </div>
  );
};

export default Label;
