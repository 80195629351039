export const formatVatRate = (vat: any): VAT => ({
  vatRateId: vat.vat_rate_id,
  name: vat.name,
  amount: Math.abs(Number(vat.amount)),
  rate: Number(vat.rate),
  disabled: vat.disabled,
  superlow: vat.superlow,
});

export const formatGetExpense = (data: any): Expense => ({
  id: data.id,
  title: data.title,
  at: new Date(data.at),

  step: data.step,

  amount: data.amount,
  amountFees: data.amount_fees,

  expenseCategory: data.expense_category,
  department: data.department,
  invoiceCountry: data.invoice_country,

  receiptUrl: data.receipt_attachment?.url,
  receiptNbPages: data.receipt_attachment?.nb_pages,
  receiptStatus: data.receipt_status,

  reversedExpenseId: data.reversed_expense_id,

  vehicle: data.vehicle,
  vehicleKilometers: data.vehicle_kilometers,
  vehicleFuelVolume: data.vehicle_fuel_volume,

  distance: data.distance,
  startPoint: data.start_point,
  endPoint: data.end_point,

  businessCode: data.business_code,
  analyticCodes: data.analytic_codes,
  analyticalAxes: data.analytical_axes,

  internalAttendees: data.internal_attendee_names,
  externalAttendees: data.external_attendee_names,

  lastComment: data.last_comment,

  isInCorrection: data.in_correction,
  isCorrectionCompleted: data.correction_completed,
  isOutOfCompanyPolicy: data.out_of_company_policy,
  isReversedExpense: data.is_reversed_expense,

  isVatRecoveryEU: data.vats_infos.config.vat_recovery_eu,
  isVatReverseChargeEU: data.vats_infos.config.vat_reverse_charge_eu,
  isVatReverseChargeOutsideEU: data.vats_infos.config.vat_reverse_charge_outside_eu,

  amountTouristTax: data.amount_tourist_tax,
  vatNumber: data.vat_number,
  companyVatNumber: data.company_vat_number,
  localCountry: {
    label: data.vats_infos.local_country.label,
    value: data.vats_infos.local_country.alpha3,
  },
  countries: data.vats_infos.invoice_countries.map(([label, alpha3]: [string, string]) => ({
    label,
    value: alpha3,
  })),
  countryVatPatterns: data.vats_infos.vat_patterns,
  countryGroup: data.vats_infos.current.rule,
  vats: data.vats_infos.current.vats.map(formatVatRate),
  vatRateId: data.vats_infos.vat_rate_id,
  vatDeductiblePercent: data.vat_deductible_percent,
  vatSensibilityThreshold: data.vat_sensibility_threshold,

  locked: data.locked,
});

export const formatPostExpenseParams = (data: ExpenseFormValues): PostExpenseParams => {
  const params: PostExpenseParams = {
    expense: {
      step: data.step,
      without_attachment: data.withoutReceipt,
      title: data.title,
      expense_category_id: data.expenseCategory?.value,
      department_id: data.department?.value,
      vehicle_id: data.vehicle?.value,
      vehicle_kilometers: data.vehicleKilometers || undefined,
      vehicle_fuel_volume: data.vehicleFuelVolume || undefined,
      business_code_name: data.businessCode === null ? null : data.businessCode?.label,
      analytic_code_names: data.analyticCodes?.map(({ label }) => label),
      amount_tourist_tax: data.amountTouristTax || undefined,
      vat_number: data.vatNumber || undefined,
      invoice_country: data.invoiceCountry || undefined,
      internal_attendee_names: data.internalAttendees?.map(({ value }) => value),
      external_attendee_names: data.externalAttendees?.map(({ value }) => value),
      vats_attributes: data.vats?.map(vat => ({
        vat_rate_id: vat.vatRateId,
        rate: vat.rate.toFixed(2),
        amount: vat.amount?.toFixed(2) || '0',
      })),
    },
    expense_analytical_axis: data.analyticalAxes
      ? data.analyticalAxes.map(({ id, value }) =>
          value && typeof value === 'object'
            ? { analytical_axis_id: id, analytical_axis_value_id: value.value }
            : { analytical_axis_id: id, value },
        )
      : undefined,
  };

  return params;
};

export const expenseToFormValues = (expense: Expense): ExpenseFormValues => ({
  withoutReceipt: expense.receiptStatus === 'lost',
  step: expense.step,
  title: expense.title || '',
  expenseCategory: expense.expenseCategory
    ? {
        label: expense.expenseCategory.name,
        value: expense.expenseCategory.id,
      }
    : null,
  department: expense.department
    ? {
        label: expense.department.name,
        value: expense.department.id,
      }
    : null,
  vehicle: expense.vehicle
    ? {
        label: expense.vehicle.name,
        value: expense.vehicle.id,
      }
    : null,
  vehicleFuelVolume: expense.vehicleFuelVolume || '',
  vehicleKilometers: expense.vehicleKilometers || '',
  businessCode: expense.businessCode
    ? {
        label: expense.businessCode.name,
        value: expense.businessCode.id,
      }
    : null,
  analyticCodes: (expense.analyticCodes || []).map((code: any) => ({
    label: code.name,
    value: code.id,
  })),
  analyticalAxes: (expense.analyticalAxes || []).map(axe => ({
    ...axe,
    value: axe.value || '',
  })),
  internalAttendees: expense.internalAttendees || [],
  externalAttendees: expense.externalAttendees || [],
  vatNumber: expense.vatNumber || '',
  invoiceCountry: expense.invoiceCountry || '',
  countryGroup: expense.countryGroup || '',
  vats: expense.vats || [],
  vatRateId: expense.vatRateId || 'none',
  amountTouristTax: expense.amountTouristTax ? Math.abs(expense.amountTouristTax) : '',
});
