import React from 'react';
import loadable from '@loadable/component';
import { paths } from './constants';

const CompanyBankInformation = loadable(() => import('../screens/CompanyBankInformation'));

const routes = [
  {
    path: paths.COMPANY_BANK_INFORMATION,
    element: <CompanyBankInformation />,
  },
];

export default routes;
