import React from 'react';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import 'twin.macro';

import { Checkbox } from '@new-components';

import { useSelectedHits } from '../contexts/SelectedHits';

const THead = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expenses.list.table' });
  const { selectedIds, selectMany } = useSelectedHits();
  const { items } = useHits();

  const hasSelected = selectedIds.length > 0;

  return (
    <thead tw="sticky top-0 z-1">
      <tr>
        <MediaQuery minWidth={768}>
          <th aria-label="Select" tw="w-12 max-w-12 p-1.5">
            <Checkbox
              isMaster
              checked={hasSelected}
              onChange={() => selectMany(hasSelected ? [] : items)}
            />
          </th>
        </MediaQuery>
        <th>{t('at')}</th>
        <th>{t('user')}</th>
        <th>{t('title')}</th>
        <th>{t('expense_category')}</th>
        <th>{t('receipt')}</th>
        <th>{t('vehicle_name')}</th>
        <th>{t('license_plate')}</th>
        <th>{t('step')}</th>
        <th>{t('amount')}</th>
      </tr>
    </thead>
  );
};

export default THead;
