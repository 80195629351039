import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  Controller,
  ControllerProps,
  useFormContext,
  RegisterOptions,
  Control,
  FieldValues,
  FieldError,
} from 'react-hook-form';

const i18nAttrs: { [k: string]: (name: string, props: any) => any } = {
  required: name => ({ attribute: name }),
  pattern: name => ({ attribute: name }),
  minLength: (name, { minLength }) => ({ attribute: name, min: minLength }),
};

export type PropsWithoutControl = RegisterOptions &
  React.PropsWithChildren<{
    name: string;
    label?: string;
    render?: ControllerProps['render'];
    onFocus?: React.FocusEventHandler<HTMLElement>;
    noError?: boolean;
    defaultValue?: any;
  }>;

export type Props = PropsWithoutControl & {
  control: Control<FieldValues, any>;
};

export const formatError = (
  t: TFunction,
  error: FieldError,
  { label, name, ...props }: { label?: string; name: string },
) =>
  error.message ||
  (error.type &&
    (t(`globals.errors.${error.type}`, i18nAttrs[error.type]?.(label || name, props)) as string));

const onChangeAsNumber =
  (onChange: (...event: any[]) => void) => (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    return onChange(target.value ? +target.value : '');
  };

export const Field = ({
  name,
  control,
  label,
  children,
  render,
  onFocus,
  onBlur,
  noError = false,
  disabled,
  defaultValue,
  ...props
}: Props) => {
  const { t } = useTranslation();

  if (render && children) throw new Error('Cannot use render and children at the same time');
  if (render)
    return (
      <Controller name={name} control={control} rules={props} render={render} disabled={disabled} />
    );

  const child = React.Children.only(children);

  if (!React.isValidElement(child)) return child;

  return (
    <Controller
      name={name}
      control={control}
      rules={props}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const error =
          !noError &&
          !!fieldState.error &&
          formatError(t, fieldState.error, { name, label, ...props });

        const onChange = props.valueAsNumber ? onChangeAsNumber(field.onChange) : field.onChange;

        return React.cloneElement(
          child as React.ReactElement<{
            name: string;
            error?: any;
            required?: boolean;
            onChange?: React.ChangeEventHandler<HTMLElement>;
            onFocus?: React.FocusEventHandler<HTMLElement>;
            onBlur?: React.FocusEventHandler<HTMLElement>;
            checked?: boolean;
          }>,
          {
            ...field,
            name,
            error,
            onChange,
            onFocus,
            onBlur: (e: React.FocusEvent<HTMLElement>) => {
              onBlur?.(e);
              field.onBlur();
            },
            required: !!props.required,
            checked: field.value === child.props.value,
          },
        );
      }}
    />
  );
};

export const ContextField = (props: PropsWithoutControl) => {
  const { control } = useFormContext();

  return <Field {...props} control={control} />;
};

export default Field;
