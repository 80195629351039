import React, { useEffect } from 'react';
import { FieldValues, FormProvider, useForm, UseFormProps } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

export type Props<T extends FieldValues> = React.PropsWithChildren<{
  defaultValues?: UseFormProps<T>['defaultValues'];
  mode?: UseFormProps<T>['mode'];
  onSubmit: (data: T) => void;
  schema?: any;
}>;

export type { Props as FieldProps } from './Field';
export * from './Field';
export * from './SubmitButton';

const Form = <T extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  mode = 'onChange',
  schema,
  ...props
}: Props<T>) => {
  const resolver = schema ? zodResolver(schema) : undefined;
  const methods = useForm<z.infer<typeof schema>>({ defaultValues, mode, resolver });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (typeof defaultValues === 'object') reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form {...props} onSubmit={handleSubmit(onSubmit)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
