import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from 'iconoir-react';
import 'twin.macro';

import { Modal, ModalProps, Button, BadgeIcon } from '@new-components';

type Props = ModalProps & {
  onAccept: () => void;
  nbHits: number;
  step: string;
};

const ConfirmBulkStepUpModal = ({ isOpen, onClose, onAccept, nbHits, step }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <BadgeIcon size="lg" color="orange">
          <InfoCircle />
        </BadgeIcon>
        <h3 tw="text-orange-700 mt-2">
          {t(
            nbHits > 1000
              ? 'expenses.bulk.modal_step_up.title_thousand'
              : 'expenses.bulk.modal_step_up.title',
            { count: nbHits, step: t(`expenses.attributes.step.${step}`) },
          )}
        </h3>
        <p tw="text-gray-600">{t('expenses.bulk.modal_step_up.subtitle')}</p>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <p>{t(`expenses.bulk.modal_step_up.${step}_text`, { count: nbHits })}</p>
      </Modal.Body>
      <Modal.Footer tw="flex gap-4 py-4">
        <Button shade="secondary" onClick={onClose} tw="flex-1">
          {t('globals.cancel')}
        </Button>
        <Button tw="flex-1" onClick={onAccept}>
          {t('expenses.bulk.modal.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBulkStepUpModal;
